import React, { useState, useEffect } from "react"
import {
	Button as MuiButton,
	Popover,
	Checkbox,
	ListItem,
	ListItemText,
	Grid,
	TextField,
	List,
	ListItemSecondaryAction,
	IconButton
} from "@mui/material"
import { makeStyles } from "@material-ui/core"
import { Button } from "./Button"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

const useStyles = makeStyles(theme => ({
	groupsDropdown: {
		// width: "464px",
		// height: "49px",
		height: "45px",
		width: "100%",
		background: "#F2F2F2 !important",
		alignItems: "center !important",
		borderRadius: "4px !important",
		marginBottom: "10px !important",
		boxShadow: "none !important",
		textTransform: "capitalize !important",
		justifyContent: "space-between !important",
		color: "#4A4A68 !important",
		fontFamily: "Poppins !important",
		fontSize: "12px !important",
		fontWeight: "500 !important",
		"&:hover .MuiButton-root": {
			borderColor: "#8C8CA1 !important",
			borderRadius: "4px !important"
		}
	},
	headerTitle: {
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontWeight: "500",
		lineHeight: "24px",
		marginRight: theme.spacing(2) // Ensure consistent margin
	},
	iconButton: {
		marginRight: theme.spacing(1),
		padding: 0 // Remove default padding
	},
	popoverContent: {
		padding: theme.spacing(2),
		width: "420px",
		maxHeight: "535px !important"
	},
	searchBox: {
		marginBottom: theme.spacing(2)
	},
	menuCheckbox: {
		paddingLeft: theme.spacing(2),
		"& .MuiListItemIcon-root": {
			minWidth: "auto"
		}
	},
	selectAllCheckbox: {
		paddingTop: "4.5px !important" // Ensure consistent padding
	},
	groupHeader: {
		fontWeight: "bold",
		fontSize: "16px",
		marginBottom: theme.spacing(1)
	},
	counter: {
		fontSize: "12px",
		color: "#8C8C8A",
		marginLeft: theme.spacing(1)
	}
}))

const Header = ({
	title,
	icon: Icon,
	subtitle,
	searchTerm,
	setSearchTerm,
	onBackClick,
	onClose,
	hasSearchBox,
	classes,
	showIcon = true // New prop
}) => (
	<Grid container alignItems="center" justifyContent="space-between" spacing={1}>
		<Grid
			item
			xs={7}
			container
			alignItems="center"
			style={{ paddingTop: showIcon ? "8px" : "4.5px" }}
		>
			{Icon && showIcon && (
				<IconButton
					edge="start"
					aria-label="icon"
					onClick={onBackClick || onClose}
					className={classes.iconButton}
				>
					<Icon />
				</IconButton>
			)}
			<span className={classes.headerTitle}>{title}</span>
			{subtitle && (
				<span
					style={{
						color: "#8C8C8A",
						fontFamily: "Poppins",
						fontSize: "12px",
						fontStyle: "normal",
						fontWeight: "500",
						lineHeight: "18px" // Ensure consistent line height
					}}
				>
					{subtitle}
				</span>
			)}
		</Grid>
		{searchTerm !== undefined && hasSearchBox && (
			<Grid item className={classes.searchBox} xs={5}>
				<TextField
					variant="outlined"
					size="small"
					placeholder="Search..."
					value={searchTerm}
					onChange={e => setSearchTerm(e.target.value)}
				/>
			</Grid>
		)}
	</Grid>
)

export default function NestedOptionsDropdown({
	selectedValues,
	setSelectedValues,
	options,
	hasSearchBox = true,
	setIsClosed,
	...props
}) {
	const [anchorEl, setAnchorEl] = useState(null)
	const [subOptions, setSubOptions] = useState([])
	const [searchTerm, setSearchTerm] = useState("")
	const [groupsCounter, setGroupsCounter] = useState(0)
	const [allGroupsSelected, setAllGroupsSelected] = useState(false)
	const [isMainPopover, setIsMainPopover] = useState(true)
	const [currentGroupLabel, setCurrentGroupLabel] = useState("")

	const classes = useStyles()

	const open = Boolean(anchorEl)

	useEffect(() => {
		// console.log("🚀 ~ selectedValues:", selectedValues)
		// console.log("🚀 ~  options:", options)
		if (!selectedValues || selectedValues.length === 0 || !options || options.length === 0) return

		const fullySelectedGroupsCount = options.reduce((count, group) => {
			const groupItems = group.subOptions?.map(option => option.name) || []
			const isGroupFullySelected =
				groupItems.length > 0 &&
				groupItems.every(item => selectedValues[group.label]?.includes(item))
			return count + (isGroupFullySelected ? 1 : 0)
		}, 0)
		setGroupsCounter(fullySelectedGroupsCount)
		setAllGroupsSelected(
			options.every(group => {
				const groupItems = group.subOptions?.map(option => option.name) || []
				return groupItems.every(item => selectedValues[group.label]?.includes(item))
			})
		)
	}, [selectedValues, options])

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
		setIsClosed(false)
		setIsMainPopover(true)
	}

	const handleClose = () => {
		setAnchorEl(null)
		setIsClosed(true)
	}

	const toggleSelectValue = (groupLabel, optionName, subOptions) => {
		const newSelected = { ...selectedValues }

		if (optionName === "Select All") {
			const groupItems = subOptions.map(option => option.name)
			const shouldSelectAll = !groupItems.every(item => newSelected[groupLabel]?.includes(item))

			if (shouldSelectAll) {
				newSelected[groupLabel] = groupItems
			} else {
				delete newSelected[groupLabel]
			}
		} else {
			const isSelected = selectedValues[groupLabel]?.includes(optionName)
			if (isSelected) {
				newSelected[groupLabel] = newSelected[groupLabel].filter(item => item !== optionName)
				if (newSelected[groupLabel].length === 0) {
					delete newSelected[groupLabel]
				}
			} else {
				newSelected[groupLabel] = newSelected[groupLabel]
					? [...newSelected[groupLabel], optionName]
					: [optionName]
			}
		}

		setSelectedValues(newSelected)
	}

	const isSelected = (group, value) =>
		!!(selectedValues[group] && selectedValues[group].includes(value))

	const handleSelectAllGroups = () => {
		const allGroupItems = options.flatMap(option =>
			Array.isArray(option.subOptions) ? option.subOptions.map(sub => sub.name) : [option.label]
		)

		const shouldSelectAll = !allGroupItems.every(item => {
			return options.some(group => selectedValues[group.label]?.includes(item))
		})

		const newSelected = shouldSelectAll
			? options.reduce((acc, group) => {
					const groupItems = group.subOptions?.map(option => option.name) || []
					return {
						...acc,
						[group.label]: groupItems
					}
			  }, {})
			: {}

		setAllGroupsSelected(shouldSelectAll)
		setSelectedValues(newSelected)
	}

	const handleGroupCheckboxChange = (groupLabel, subOptions) => {
		const groupItems = subOptions.map(option => option.name)
		const shouldSelectAll = !groupItems.every(item => selectedValues[groupLabel]?.includes(item))

		const newSelected = { ...selectedValues }
		if (shouldSelectAll) {
			newSelected[groupLabel] = groupItems
		} else {
			delete newSelected[groupLabel]
		}

		setSelectedValues(newSelected)
	}

	const renderSubOptions = (groupLabel, subOptions) => (
		<>
			{subOptions
				.filter(option => option.name.toLowerCase().includes(searchTerm.toLowerCase()))
				.map(option => (
					<ListItem
						key={option.value}
						// button
						onClick={() => toggleSelectValue(groupLabel, option.name, subOptions)}
						style={{ paddingLeft: "0", paddingRight: "0" }}
					>
						<Checkbox
							checked={isSelected(groupLabel, option.name)}
							color="primary"
							className={classes.menuCheckbox}
						/>
						<ListItemText primary={option.name} />
					</ListItem>
				))}
		</>
	)

	const handleGroupClick = group => {
		const selectedGroup = options.find(option => option.label === group.label)
		if (selectedGroup) {
			setSubOptions(selectedGroup.subOptions || [])
			setCurrentGroupLabel(group.label)
			setIsMainPopover(false)
		}
	}

	// Calculate the number of selected sub-options and total sub-options for a given group
	const getSelectedCountForGroup = group => {
		const groupItems = group.subOptions?.map(option => option.name) || []
		const selectedCount = groupItems.filter(item => isSelected(group.label, item)).length
		return { selectedCount, totalCount: groupItems.length }
	}

	const renderMainPopover = () => (
		<>
			<Grid container spacing={2} alignItems="center">
				<Grid item xs={1}>
					<Checkbox
						checked={allGroupsSelected}
						onChange={handleSelectAllGroups}
						color="primary"
						className={classes.selectAllCheckbox}
					/>
				</Grid>
				<Grid item xs={11}>
					<Header
						title="Groups"
						subtitle={`${groupsCounter}/${options.length} selected`}
						searchTerm={searchTerm}
						setSearchTerm={setSearchTerm}
						onBackClick={handleSelectAllGroups}
						icon={Checkbox}
						hasSearchBox={hasSearchBox}
						classes={classes}
						showIcon={false}
					/>
				</Grid>
			</Grid>

			<List>
				{options.map(group => {
					const { selectedCount, totalCount } = getSelectedCountForGroup(group)
					return (
						<ListItem key={group.label} style={{ paddingLeft: "0", paddingRight: "0" }}>
							<Checkbox
								checked={
									!!group.subOptions?.every(option => isSelected(group.label, option.name))
								}
								color="primary"
								onChange={() => handleGroupCheckboxChange(group.label, group.subOptions)}
							/>
							<ListItemText
								primary={
									<span style={{ display: "flex", alignItems: "center" }}>
										{group.label}
										<span className={classes.counter}>
											{selectedCount}/{totalCount} selected
										</span>
									</span>
								}
							/>
							<ListItemSecondaryAction>
								<IconButton
									edge="end"
									aria-label="more"
									onClick={() => handleGroupClick(group)}
								>
									<ArrowForwardIosIcon />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
					)
				})}
			</List>
		</>
	)

	const renderSubOptionsPopover = () => {
		const checkedCount = subOptions.filter(option =>
			isSelected(currentGroupLabel, option.name)
		).length
		return (
			<div style={{ position: "relative", padding: "8px" }}>
				<Header
					title={currentGroupLabel}
					subtitle={`${checkedCount}/${subOptions.length} selected`}
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					onBackClick={() => setIsMainPopover(true)}
					icon={ArrowBackIosIcon}
					classes={classes}
				/>
				<List>{renderSubOptions(currentGroupLabel, subOptions)}</List>
			</div>
		)
	}

	const getSelectedValuesDisplay = () => {
		// Flatten the selected values from all groups
		const allSelectedValues = Object.values(selectedValues).flat()

		// Set the limit for the number of items to display
		const displayLimit = 5

		// Get the items to display and append "..." if there are more
		const displayedValues = allSelectedValues.slice(0, displayLimit)
		const displayString = displayedValues.join(", ")

		return allSelectedValues.length > displayLimit
			? `${displayString} ...`
			: displayString || "Groups..."
	}

	return (
		<div {...props}>
			<MuiButton
				variant="contained"
				className={classes.groupsDropdown}
				onClick={handleClick}
				endIcon={<KeyboardArrowDownIcon />}
			>
				{getSelectedValuesDisplay()}
			</MuiButton>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left"
				}}
				classes={{ paper: classes.popoverContent }}
			>
				{isMainPopover ? renderMainPopover() : renderSubOptionsPopover()}
			</Popover>
		</div>
	)
}
