import React, { useEffect, useState } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { DataGrid, GridToolbarDensitySelector, GridToolbarFilterButton } from "@mui/x-data-grid"
import PropTypes from "prop-types"
import ClearIcon from "@material-ui/icons/Clear"
import SearchIcon from "@material-ui/icons/Search"
import { makeStyles, IconButton, TextField } from "@material-ui/core"
import { Button } from "../../../../../_partials/Button"
import { UserGrouping } from "../UserGrouping.js/UserGrouping"
import { TiInputChecked } from "react-icons/ti"
import { DeleteSeveralModal } from "../modals/DeleteSeveralModal"
import ApproveModal from "../modals/ApproveModal"

const AnchorsTable = ({
	initialData,
	setInitialData,
	anchorsPositioning,
	selectedRows,
	setSelectedRows,
	unplaceAnchors,
	showDeleteSeveral,
	setShowDeleteSeveral,
	showApproveModal,
	setShowApproveModal,
	saveApprovedAnchors
}) => {
	const classes = useStyles()

	const { currentFloorPlan, anchorsPositionData } = useSelector(
		state => ({
			currentFloorPlan: state.profile?.currentFloorPlan,
			anchorsPositionData: state.designStudio?.anchorsPosition
		}),
		shallowEqual
	)
	//━━━━━━━━━━━━━━ States ━━━━━━━━━━━━━━\\
	// const [initialData, setInitialData] = useState([])
	// const [selectedRows, setSelectedRows] = useState([])
	const [dataParsed, setDataParsed] = useState([])
	const [searchText, setSearchText] = React.useState("")
	// const [showDeleteSeveral, setShowDeleteSeveral] = useState(false)

	const handleRowSelection = newSelection => {
		setSelectedRows(newSelection)
		// console.log("Selected Rows:", newSelection)
	}

	const requestSearch = searchValue => {
		const trimmedValue = searchValue.trim()

		setSearchText(searchValue)
		const searchRegex = new RegExp(escapeRegExp(trimmedValue), "i")

		// const allData =
		//   timeframe === "day"
		//     ? mockDataRowsDailyContractedDay
		//     : timeframe === "week"
		//     ? mockDataRowsDailyContractedWeek()
		//     : mockDataRowsDailyContractedMonth();

		const allData = dataParsed

		const filteredRows =
			allData &&
			allData.filter(row => {
				return Object.keys(row).some(field => {
					return searchRegex.test(row[field].toString())
				})
			})

		setDataParsed(filteredRows)
	}

	// When the search filter is with empty string it displays all data again without being filtered
	useEffect(() => {
		if (searchText === "") {
			setDataParsed(initialData)
			// console.log("🚀  initialData:", initialData)
		}
	}, [searchText, initialData])

	// Set data to State
	useEffect(() => {
		if (!currentFloorPlan || !anchorsPositioning) return

		const filteredByCurrentFloorplan = anchorsPositioning.filter(
			val =>
				val?.floorPlanId === currentFloorPlan?.id &&
				!val.approved &&
				(val.status === "placed" || val.status === "planned")
		)

		const anchorsPositionDataClone = [...filteredByCurrentFloorplan]

		const anchorsPositionDataCloneWithFloorName = anchorsPositionDataClone.map(val => {
			return {
				...val,
				// placedOnSite: val?.placedOnSite ? "Placed" : "Planned",
				floorPlanName: currentFloorPlan.name
			}
		})

		anchorsPositionDataCloneWithFloorName && setInitialData(anchorsPositionDataCloneWithFloorName) // set an initial Data with fetched data so that when the user clears the search input the data of the table returns to the initialData
		anchorsPositionDataCloneWithFloorName && setDataParsed(anchorsPositionDataCloneWithFloorName)
	}, [anchorsPositioning, currentFloorPlan])

	return (
		<>
			{/* <div style={{ height: 550, width: "100%", overflow: "auto" }} className={classes.table}> */}
			<div className={classes.table}>
				<style>
					{/* Change the font-family of the table */}{" "}
					{/* And change the background color  of the pagination othe table  */}
					{`
          .MuiDataGrid-root {
            font-family: 'Poppins', sans-serif;
          }
		 
		  .MuiTablePagination-root {
       		 background-color: #FFFFFF;
      		}

			  .MuiTablePagination-selectRoot { 
       		 background-color: #FFFFFF !important;
			 color: #212121 !important;
      		}

			  .MuiTablePagination-selectRoot:hover { 
       		 background-color: #FFFFFF !important;
      		}

			  .MuiTablePagination-caption {
       		 background-color: #FFFFFF;
      		}

			  .MuiTablePagination-caption:hover {
       		 background-color: #FFFFFF !important;
      		}

			  .MuiTablePagination-selectRoot { 
       		 background-color: #FFFFFF;
      		}

			
		

			
        `}
				</style>
				<DataGrid
					rows={dataParsed || []}
					columns={columns}
					pageSize={10}
					checkboxSelection
					onSelectionModelChange={handleRowSelection}
					components={{ Toolbar: QuickSearchToolbar }}
					componentsProps={{
						toolbar: {
							value: searchText,
							onChange: event => requestSearch(event.target.value),
							clearSearch: () => requestSearch(""),
							selectedRows: selectedRows,
							initialData: initialData,
							setShowDeleteSeveral: setShowDeleteSeveral,
							setShowApproveModal: setShowApproveModal
						}
					}}
					disableColumnMenu
					autoHeight={true}
				/>
			</div>
			<DeleteSeveralModal
				showDeleteSeveral={showDeleteSeveral}
				setShowDeleteSeveral={setShowDeleteSeveral}
				unplaceAnchors={unplaceAnchors}
			/>
			<ApproveModal
				showApproveModal={showApproveModal}
				setShowApproveModal={setShowApproveModal}
				selectedRows={selectedRows}
				initialData={initialData}
				saveApprovedAnchors={saveApprovedAnchors}
			/>
		</>
	)
}

export default AnchorsTable

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(1, 1, 1, 1),
		justifyContent: "space-between",
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		// filters and densitiy icons color
		"& .MuiButton-textPrimary": {
			color: "#192942"
		}
	},
	table: {
		"& .MuiDataGrid-root": {
			border: "none" // Remove the border
		},
		"& .MuiTablePagination-toolbar": {
			position: "static"
		},
		"& .MuiDataGrid-window": {
			overflowY: "hidden !important"
		}
	},
	textField: {
		[theme.breakpoints.down("xs")]: {
			width: "100%"
		},
		margin: theme.spacing(1, 0.5, 1.5),
		"& .MuiSvgIcon-root": {
			marginRight: theme.spacing(0.5)
		},
		// "& .MuiInput-underline:before": {
		//   borderBottom: `1px solid ${theme.palette.divider}`,
		// },
		backgroundColor: "#ecf1f4",
		"& .MuiSelect-root": {
			minWidth: "8rem"
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "#ecf1f4"
			}
		},
		"&.Mui-focused fieldset": {
			borderColor: "#192942"
		}
	}
}))

const columns = [
	{ field: "uuid", headerName: "UUID", width: 150 },
	{
		// field: "placedOnSite",
		field: "status",
		headerName: "Anchor Status",
		width: 200
	},
	{
		field: "description",
		headerName: "Description",
		width: 200,

		renderCell: params => (
			<div
				style={{
					height: "100%",
					overflow: params?.value?.length > 30 ? "visible" : "hidden",
					overflowX: "auto" // Enable horizontal scrolling
				}}
			>
				{params.value}
			</div>
		)
	},
	{ field: "floorPlanName", headerName: "Floorplan", width: 200 }
]

function QuickSearchToolbar(props) {
	const { selectedRows, initialData, setShowDeleteSeveral, setShowApproveModal } = props
	const classes = useStyles()

	// const placedRows = initialData && initialData.filter(d => d.placedOnSite === "Placed")
	const placedRows = initialData && initialData.filter(d => d.status === "placed")

	const foundedPlacedRows =
		selectedRows && selectedRows.some(r => placedRows && placedRows.find(val => val.id === r))

	return (
		<div className={classes.root}>
			<div className="d-flex align-items-center">
				{/* <GridToolbarFilterButton /> */}
				{/* <GridToolbarDensitySelector /> */}
				<UserGrouping selectedRows={selectedRows} setShowDeleteSeveral={setShowDeleteSeveral} />

				<div className="ml-4">
					<Button
						disabled={!foundedPlacedRows}
						onClick={() => setShowApproveModal(true)}
						variant="contained"
						color="primary"
						text="Approve"
						endIcon={<TiInputChecked />}
					/>
				</div>
			</div>
			<div className="d-flex align-items-center">
				<TextField
					size="small"
					variant="outlined"
					value={props.value}
					onChange={props.onChange}
					placeholder="Search…"
					className={classes.textField}
					InputProps={{
						startAdornment: (
							<SearchIcon
								fontSize="small"
								style={{
									color: "#7F8C8D",
									marginRight: "0.7rem"
								}}
							/>
						),
						endAdornment: (
							<IconButton
								title="Clear"
								aria-label="Clear"
								size="small"
								style={{ visibility: props.value ? "visible" : "hidden" }}
								onClick={props.clearSearch}
							>
								<ClearIcon fontSize="small" />
							</IconButton>
						)
					}}
				/>
			</div>
		</div>
	)
}

QuickSearchToolbar.propTypes = {
	clearSearch: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired
}

function escapeRegExp(value) {
	return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
}
