// import { firestoreOld } from "../../../../firebase"
// import firebase from "firebase/compat/app"
// import moment from "moment"

// const customersRef = firestoreOld.collection("Customers")
// const sitesRef = firestoreOld.collection("Sites")

// export function getIconsFromDB() {
// 	return firestoreOld
// 		.collection("GlobalOptions")
// 		.doc("pointsOfInterestIcons")
// 		.collection("POIsIcons")
// 		.orderBy("listOrder", "asc")
// 		.get()
// }
import { firestore as db } from "../../../../firebase" // Adjust import based on your Firebase setup
import {
	collection,
	doc,
	getDocs,
	getDoc,
	serverTimestamp,
	setDoc,
	orderBy,
	where,
	query
} from "firebase/firestore"
import moment from "moment"

export function getIconsFromDB() {
	const iconsCollectionRef = collection(
		doc(db, "GlobalOptions", "pointsOfInterestIcons"),
		"POIsIcons"
	)
	const iconsQuery = query(iconsCollectionRef, orderBy("listOrder", "asc"))

	return getDocs(iconsQuery).then(snapshot => {
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))
	})
}

// export function getThings(queryParams) {
// 	return customersRef.doc(queryParams.customerId).collection("Things").get()
// }
export function getThings(queryParams) {
	const thingsCollectionRef = collection(doc(db, "Customers", queryParams.customerId), "Things")

	return getDocs(thingsCollectionRef).then(snapshot => {
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))
	})
}

// export async function getUsers(queryParams) {
// 	const userDoc = await firestoreOld
// 		.doc(`Customers/${queryParams.customerId}/Users/${queryParams.userId}`)
// 		.get()

// 	const user = userDoc.data()
// 	if (!user?.buddies?.length > 0) {
// 		return
// 	}
// 	var newUsers = [user]

// 	const results = await Promise.all(
// 		user.buddies.map(val => {
// 			return firestoreOld.doc(`Customers/${queryParams.customerId}/Users/${val}`).get()
// 		})
// 	)
// 	const newBuddies = results.map(doc => doc.data())
// 	newUsers = [...newUsers, ...newBuddies]
// 	return newUsers
// }
export async function getUsers(queryParams) {
	const userDocRef = doc(db, `Customers/${queryParams.customerId}/Users/${queryParams.userId}`)
	const userDoc = await getDoc(userDocRef)

	const user = userDoc.data()
	if (!user?.buddies?.length > 0) {
		return
	}

	let newUsers = [user]

	// Create an array of document references for buddies
	const buddyDocRefs = user.buddies.map(val =>
		doc(db, `Customers/${queryParams.customerId}/Users/${val}`)
	)

	// Fetch all buddy documents
	const results = await Promise.all(buddyDocRefs.map(ref => getDoc(ref)))

	// Extract buddy data
	const newBuddies = results.map(doc => doc.data())
	newUsers = [...newUsers, ...newBuddies]

	return newUsers
}

// export function getPeopleStats(queryParams) {
// 	if (!queryParams) {
// 		return Promise.resolve(null)
// 	}

// 	return customersRef
// 		.doc(queryParams.customerId)
// 		.collection(queryParams.selectedLocation)
// 		.doc("peopleStats")
// 		.get()
// }
export function getPeopleStats(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	const peopleStatsDocRef = doc(
		db,
		`Customers/${queryParams.customerId}/${queryParams.selectedLocation}/peopleStats`
	)

	return getDoc(peopleStatsDocRef)
}

// export function getAllLocationsPeopleStats(queryParams) {
// 	if (!queryParams) {
// 		return Promise.resolve(null)
// 	}

// 	return Promise.all(
// 		queryParams.customerId.merakiNetworks.map(location => {
// 			return customersRef
// 				.doc(queryParams.customerId.id)
// 				.collection(location.netId)
// 				.doc("peopleStats")
// 				.get()
// 		})
// 	)
// }
export function getAllLocationsPeopleStats(queryParams) {
	if (!queryParams || !queryParams.customerId || !queryParams.customerId.merakiNetworks) {
		return Promise.resolve(null)
	}

	const { customerId, merakiNetworks } = queryParams

	const fetchStatsPromises = merakiNetworks.map(location => {
		const docRef = doc(db, `Customers/${customerId.id}/${location.netId}/peopleStats`)
		return getDoc(docRef)
	})

	return Promise.all(fetchStatsPromises)
}

// export function getAllLocationsMerakiDevices(queryParams) {
// 	if (!queryParams) {
// 		return Promise.resolve(null)
// 	}

// 	return Promise.all(
// 		queryParams.customerId.merakiNetworks.map(location => {
// 			return customersRef
// 				.doc(queryParams.customerId.id)
// 				.collection(location.netId)
// 				.doc("merakiDevices")
// 				.get()
// 		})
// 	)
// }
export function getAllLocationsMerakiDevices(queryParams) {
	if (!queryParams || !queryParams.customerId || !queryParams.customerId.merakiNetworks) {
		return Promise.resolve(null)
	}

	const { customerId, merakiNetworks } = queryParams

	const fetchDevicesPromises = merakiNetworks.map(location => {
		const docRef = doc(db, `Customers/${customerId.id}/${location.netId}/merakiDevices`)
		return getDoc(docRef)
	})

	return Promise.all(fetchDevicesPromises)
}

export function getDailyUsers(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	var startDate = new moment()
	var endDate = new moment()
	startDate.subtract(endDate.date() - 1, "days")

	if (queryParams.selectedLocation.customerType == "office") {
		var SSIDs = JSON.parse(JSON.stringify(queryParams?.selectedLocation?.SSIDs))
		SSIDs && SSIDs.push("")
	} else if (queryParams.selectedLocation.customerType == "shop") {
		var SSIDs = JSON.parse(JSON.stringify(queryParams?.selectedLocation?.guestSSID))
		SSIDs && SSIDs.push("")
	}

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
	myHeaders.append("Content-Type", "application/json")
	var raw = JSON.stringify({
		typechart: "globalInfo",
		networkId: queryParams.selectedLocation.netId,
		ssid: SSIDs,
		startDate: moment(startDate).format("YYYY-MM-DD"),
		endDate: moment(endDate).format("YYYY-MM-DD[T23:59:59]")
	})
	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}
	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/floors/${queryParams.selectedCustomer.customerId}`,
		requestOptions
	).catch(error => console.log("Error", error))

	// return fetch(`https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/floors/${queryParams.customer.customerId}`, requestOptions)
}

// export function getPois(queryParams) {
// 	if (!queryParams) {
// 		return Promise.resolve(null)
// 	}

// 	return sitesRef.doc(queryParams.siteId).collection("PointsOfInterest").get()
// }
export async function getPois(queryParams) {
	if (!queryParams || !queryParams.siteId) {
		return Promise.resolve(null)
	}

	try {
		const poisCollectionRef = collection(doc(db, "Sites", queryParams.siteId), "PointsOfInterest")
		const poisSnapshot = await getDocs(poisCollectionRef)
		const pois = poisSnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))
		return pois
	} catch (error) {
		console.error("Error fetching Points of Interest:", error)
		return Promise.reject(error)
	}
}

// export function getAreasInDB(queryParams) {
// 	if (!queryParams) {
// 		return Promise.resolve(null)
// 	}

// 	return sitesRef.doc(queryParams.siteId).collection("Areas").get()
// }
export async function getAreasInDB(queryParams) {
	if (!queryParams || !queryParams.siteId) {
		return Promise.resolve(null)
	}

	try {
		const areasCollectionRef = collection(doc(db, "Sites", queryParams.siteId), "Areas")
		const areasSnapshot = await getDocs(areasCollectionRef)
		const areas = areasSnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))
		return areas
	} catch (error) {
		console.error("Error fetching Areas:", error)
		return Promise.reject(error)
	}
}

// export function getPreferencesInDB(queryParams) {
// 	if (!queryParams || !queryParams?.userId) {
// 		return Promise.resolve(null)
// 	}

// 	return firestoreOld.collection("UserPreferences").doc(queryParams.userId).get()
// }
export async function getPreferencesInDB(queryParams) {
	if (!queryParams || !queryParams.userId) {
		return Promise.resolve(null)
	}

	try {
		const preferencesDocRef = doc(db, "UserPreferences", queryParams.userId)
		const preferencesDoc = await getDoc(preferencesDocRef)
		if (preferencesDoc) {
			return { id: preferencesDoc.id, ...preferencesDoc.data() }
		} else {
			console.log("No such document!")
			return null
		}
	} catch (error) {
		console.error("Error fetching preferences:", error)
		return Promise.reject(error)
	}
}

// export function getZonesInDB({ selectedCustomer, selectedLocation, floorPlanSelected }) {
// 	if (!selectedCustomer || !selectedLocation || !floorPlanSelected) {
// 		return Promise.resolve(null)
// 	}

// 	return customersRef
// 		.doc(selectedCustomer)
// 		.collection(`Zones`)
// 		.where("netId", "==", selectedLocation)
// 		.where("floorPlanId", "==", floorPlanSelected.id)
// 		.get()
// }

// export function getResourcesMeetings({ selectedCustomer, selectedLocation, floorPlanSelected }) {
// 	if (!selectedCustomer) {
// 		return Promise.resolve(null)
// 	}

// 	return (
// 		customersRef
// 			.doc(selectedCustomer)
// 			.collection(`Resources`)
// 			.where("networkId", "==", selectedLocation)
// 			// .where("floorPlanId", "==", floorPlanSelected.id)
// 			.where("type", "==", "meeting")
// 			.orderBy("created.date")
// 			.get()
// 	)
// }
export async function getResourcesMeetings({
	selectedCustomer,
	selectedLocation,
	floorPlanSelected
}) {
	if (!selectedCustomer) {
		return Promise.resolve(null)
	}

	try {
		const resourcesCollectionRef = collection(doc(db, "Customers", selectedCustomer), "Resources")
		const resourcesQuery = query(
			resourcesCollectionRef,
			where("networkId", "==", selectedLocation),
			where("type", "==", "meeting"),
			orderBy("created.date")
		)

		const querySnapshot = await getDocs(resourcesQuery)
		const resources = querySnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		return resources
	} catch (error) {
		console.error("Error fetching resources:", error)
		return Promise.reject(error)
	}
}

// export function createNodes(queryParams) {
// 	if (!queryParams || !queryParams?.newNodesArray?.[0]) {
// 		return Promise.resolve(null)
// 	}

// 	return Promise.all(
// 		queryParams.newNodesArray.map(node => {
// 			const generatedUUID = node.uuid.toString()
// 			// console.log("🚀  generatedUUID:", generatedUUID)

// 			return sitesRef
// 				.doc(queryParams.selectedSite.id)
// 				.collection("Nodes")
// 				.doc(generatedUUID)
// 				.set({
// 					mockNode: node.mockNode,
// 					floorPlanId: node.floorPlanId,
// 					siteId: node.siteId,
// 					gpsData: node.gpsData,
// 					status: node.status,
// 					tagState: node.tagState,
// 					nodeType: node.nodeType,
// 					networkAddress: node.networkAddress,
// 					uuid: node.uuid,
// 					is_approved: node.is_approved,
// 					geoHash: node.geoHash
// 				})
// 				.then(() => {
// 					console.log(`Node successfully created for UUID: ${generatedUUID}`)
// 				})
// 				.catch(error => {
// 					console.error(`Error creating node for UUID ${generatedUUID}:`, error)
// 				})
// 		})
// 	).catch(error => {
// 		console.error("Error creating nodes:", error)
// 	})
// }
export async function createNodes(queryParams) {
	if (!queryParams || !queryParams.newNodesArray || !queryParams.newNodesArray[0]) {
		return Promise.resolve(null)
	}

	try {
		const batch = db.batch()
		const siteRef = doc(db, "Sites", queryParams.selectedSite.id)
		const nodesCollectionRef = collection(siteRef, "Nodes")

		queryParams.newNodesArray.forEach(node => {
			const nodeRef = doc(nodesCollectionRef, node.uuid.toString())
			batch.set(nodeRef, {
				mockNode: node.mockNode,
				floorPlanId: node.floorPlanId,
				siteId: node.siteId,
				gpsData: node.gpsData,
				status: node.status,
				tagState: node.tagState,
				nodeType: node.nodeType,
				networkAddress: node.networkAddress,
				uuid: node.uuid,
				is_approved: node.is_approved,
				geoHash: node.geoHash
			})
		})

		await batch.commit()
		console.log("Nodes successfully created.")
	} catch (error) {
		console.error("Error creating nodes:", error)
		return Promise.reject(error)
	}
}

// export function saveUserFilterPreferences(queryParams) {
// 	if (!queryParams || !queryParams[0] || !queryParams[0].userId || !queryParams[0].preferences) {
// 		console.error("Invalid queryParams. Unable to save preferences.")
// 		return Promise.resolve(null)
// 	}

// 	const userId = queryParams?.[0].userId
// 	const preferences = queryParams?.[0].preferences

// 	return firestoreOld
// 		.collection("UserPreferences")
// 		.doc(userId)
// 		.set({
// 			userId: userId,
// 			preferences: preferences,
// 			updated: {
// 				id: userId,
// 				date: firebase.firestore.FieldValue.serverTimestamp()
// 			}
// 		})
// }
export async function saveUserFilterPreferences(queryParams) {
	if (!queryParams || !queryParams[0] || !queryParams[0].userId || !queryParams[0].preferences) {
		console.error("Invalid queryParams. Unable to save preferences.")
		return Promise.resolve(null)
	}

	const userId = queryParams[0].userId
	const preferences = queryParams[0].preferences

	try {
		const userPreferencesRef = doc(db, "UserPreferences", userId)
		await setDoc(userPreferencesRef, {
			userId: userId,
			preferences: preferences,
			updated: {
				id: userId,
				date: serverTimestamp()
			}
		})
		console.log("User preferences successfully saved.")
	} catch (error) {
		console.error("Error saving user preferences:", error)
		return Promise.reject(error)
	}
}
