import moment from "moment"
import { firestore } from "../../../../firebase"
import { userMocked } from "./contactTracingMockData"
import {
	getFirestore,
	serverTimestamp,
	collection,
	doc,
	getDocs,
	query,
	where,
	setDoc
} from "firebase/firestore"

// const customersRef = firestore.collection("Customers")

// export function getUsers(queryParams) {
// 	return (
// 		customersRef
// 			.doc(queryParams.customerId)
// 			.collection("People")
// 			//.where("username", "!=", "")
// 			.get()
// 	)
// }

// export function getUsersOnSelectedSite({ customerId, selectedSite }) {
// 	if (!customerId && !selectedSite && !selectedSite?.id) {
// 		return Promise.resolve(null)
// 	}

// 	return Promise.all([
// 		customersRef.doc(customerId).collection("People").get(),
// 		firestore
// 			.collection(`Sites/${selectedSite?.id}/Nodes`)
// 			.where("nodeType", "==", "tag")
// 			.get()
// 	])
// }

export async function getUsersOnSelectedSite({ customerId, selectedSite }) {
	if (!customerId || !selectedSite?.id) {
		return null
	}

	const nodesCollection = global.nodesCollection

	try {
		// Get People from the customer's collection
		const peopleRef = collection(doc(firestore, "Customers", customerId), "People")
		const peopleSnapshot = await getDocs(peopleRef)
		const people = peopleSnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		// Get Nodes with nodeType == 'tag' from the selected site
		const nodesRef = collection(doc(firestore, "Sites", selectedSite.id), nodesCollection)
		const nodesQuery = query(nodesRef, where("nodeType", "==", "tag"))
		const nodesSnapshot = await getDocs(nodesQuery)
		const nodes = nodesSnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		return { people, nodes }
	} catch (error) {
		console.error("Error fetching data:", error)
		return null
	}
}

// export async function getContactTracing(queryParams) {
// 	// Commented this for using just the mock data
// 	await customersRef
// 		.doc(queryParams.customerId)
// 		.collection("ContactTracingLogs")
// 		.doc()
// 		.set({
// 			userId: queryParams.user.id,
// 			email: queryParams.user.email,
// 			// reason: queryParams.reason,
// 			//  date: firebase.firestore.Timestamp.now(),
// 			date: serverTimestamp(),
// 			report: {
// 				siteId: queryParams.siteId,
// 				networkId: queryParams.networkId,
// 				// macAddress: queryParams.macAddress,
// 				startDate: queryParams.startDate,
// 				endDate: queryParams.endDate,
// 				queriedUserName: queryParams.queriedUserName,
// 				queriedUser: queryParams.queriedUser,
// 				uuid: queryParams.queriedUser.uuid
// 			}
// 		})

// 	var myHeaders = new Headers()
// 	myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
// 	myHeaders.append("Content-Type", "application/json")

// 	// console.log({
// 	// 	typechart: "beaconFlow",
// 	// 	siteId: queryParams.siteId,
// 	// 	networkId: queryParams.networkId,
// 	// 	// macAddress: queryParams.macAddress,
// 	// 	dateStart: queryParams.startDate,
// 	// 	dateEnd: queryParams.endDate,
// 	// 	uuid: [queryParams.queriedUser.uuid]
// 	// })

// 	var raw = JSON.stringify({
// 		typechart: "beaconFlow",
// 		siteId: queryParams.siteId,
// 		networkId: queryParams.networkId,
// 		// macAddress: queryParams.macAddress,
// 		dateStart: queryParams.startDate,
// 		dateEnd: queryParams.endDate,
// 		uuid: [queryParams.queriedUser.uuid]
// 	})

// 	// console.log("🚀  raw:", raw)

// 	var requestOptions = {
// 		method: "POST",
// 		headers: myHeaders,
// 		body: raw,
// 		redirect: "follow"
// 	}

// 	return fetch(
// 		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/audit-tag/${queryParams.customerId}`,
// 		requestOptions
// 	)

// 	// Mockdata
// 	// return userMocked(queryParams.startDate, queryParams.endDate, queryParams.areasData)
// }
export async function getContactTracing(queryParams) {
	if (
		!queryParams ||
		!queryParams.customerId ||
		!queryParams.user ||
		!queryParams.siteId ||
		!queryParams.networkId ||
		!queryParams.startDate ||
		!queryParams.endDate ||
		!queryParams.queriedUser
	) {
		console.error("Missing required query parameters.")
		return null
	}

	try {
		// Save contact tracing log to Firestore
		const contactTracingRef = doc(
			collection(doc(firestore, "Customers", queryParams.customerId), "ContactTracingLogs")
		)
		await setDoc(contactTracingRef, {
			userId: queryParams.user.id,
			email: queryParams.user.email,
			date: serverTimestamp(),
			report: {
				siteId: queryParams.siteId,
				networkId: queryParams.networkId,
				startDate: queryParams.startDate,
				endDate: queryParams.endDate,
				queriedUserName: queryParams.queriedUserName,
				queriedUser: queryParams.queriedUser,
				uuid: queryParams.queriedUser.uuid
			}
		})

		// Prepare and send fetch request
		const myHeaders = new Headers({
			"X-API-Key": process.env.REACT_APP_CUSTOMER_API_KEY,
			"Content-Type": "application/json"
		})

		const raw = JSON.stringify({
			typechart: "beaconFlow",
			siteId: queryParams.siteId,
			networkId: queryParams.networkId,
			dateStart: queryParams.startDate,
			dateEnd: queryParams.endDate,
			uuid: [queryParams.queriedUser.uuid]
		})

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow"
		}

		const response = await fetch(
			`${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/audit-tag/${queryParams.customerId}`,
			requestOptions
		)
		return response
	} catch (error) {
		console.error("Error processing contact tracing:", error)
		return null
	}
}

export function getPersonLocationAuditTool(queryParams) {
	// console.log("🚀  queryParams:", queryParams)

	// // Commented this for using just the mock data
	// await customersRef
	// 	.doc(queryParams.customerId)
	// 	.collection("ContactTracingLogs")
	// 	.doc()
	// 	.set({
	// 		userId: queryParams.user.id,
	// 		email: queryParams.user.email,
	// 		reason: queryParams.reason,
	// 		//  date: firebase.firestore.Timestamp.now(),
	// 		date: serverTimestamp(),
	// 		report: {
	// 			siteId: queryParams.siteId,
	// 			networkId: queryParams.networkId,
	// 			// macAddress: queryParams.macAddress,
	// 			startDate: queryParams.startDate,
	// 			endDate: queryParams.endDate,
	// 			queriedUserName: queryParams.queriedUserName,
	// 			queriedUser: queryParams.queriedUser,
	// 			uuid: queryParams.queriedUser.uuid
	// 		}
	// 	})

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
	myHeaders.append("Content-Type", "application/json")

	var raw = JSON.stringify({
		typechart: "allData",
		siteId: queryParams.siteId,
		networkId: queryParams.networkId,
		dateStart: queryParams.startDate,
		dateEnd: queryParams.endDate,
		uuid: [queryParams.queriedUser.uuid]
	})

	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/audit-tag/${queryParams.customerId}`,
		requestOptions
	)

	// Mockdata
	// return userMocked(queryParams.startDate, queryParams.endDate, queryParams.areasData)
}

export function getAllThePeopleDistribution(queryParams) {
	if (!queryParams.customerId || !queryParams?.startDate || !queryParams?.endDate)
		return Promise.resolve(null)

	return fetchAPI({
		// siteId: queryParams.siteId,
		networkId: queryParams.selectedSite.netWirepas,
		timeZone: queryParams.selectedSite.timeZone,
		typechart: "timesheet",
		dateStart: queryParams.startDate,
		dateEnd: queryParams.endDate,
		// endDate: queryParams.date,
		url: `/timesheets/${queryParams.customerId}`
	})
}

function fetchAPI(props) {
	// console.log("🚀  props:", props)

	const {
		typechart,
		networkId,
		// SSIDs,
		// floors,
		// zones,
		areas,
		dateStart,
		dateEnd,
		url,
		floorPlanId,
		uuid,
		siteId,
		timeZone
	} = props
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
	myHeaders.append("Content-Type", "application/json")

	// Set dates to correct times and then change those times to match UTC
	const startDate = moment(dateStart).startOf("day").format("YYYY-MM-DDTHH:mm:ss")
	const endDate = moment(dateEnd).endOf("day").format("YYYY-MM-DDTHH:mm:ss")

	const raw = JSON.stringify({
		// ssid: SSIDs,
		// floorPlanId: floors || [],
		// zones: zones || [],
		// dateStart: moment(startDate).format("YYYY-MM-DDT00:00:00+01:00"),
		// dateEnd: moment(endDate).format("YYYY-MM-DDT23:59:59+01:00")
		// dateEnd: moment(endDate).format("YYYY-MM-DD[T23:59:59]"), //TODO: Lembrar o Rui para receber o time no dateEnd e dateStart tb no timesheet API.
		siteId: siteId || "",
		typechart: typechart,
		networkId: networkId,
		timeZone: timeZone,
		// dateStart: moment(dateStart).format("YYYY-MM-DD"),
		// dateEnd: moment(dateEnd).format("YYYY-MM-DD"),
		dateStart: startDate,
		dateEnd: endDate,
		floorPlanId: floorPlanId || "",
		uuid: uuid || "",
		areas: areas || []
	})

	// console.log(raw, "raw")

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(`${process.env.REACT_APP_CUSTOMER_API_BASE_URL}${url}`, requestOptions).catch(
		err => {
			console.log("Error: ", err)
		}
	)
}
