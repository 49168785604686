import React, { forwardRef, useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../../_redux/managePeople/managePeopleActions"
import { Input } from "../../../../_partials/inputs/Input"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { Autocomplete_People } from "../../../../_partials/inputs/Autocomplete_PeopleTable"
import { SelectMultiCheckbox } from "../../../../_partials/inputs/SelectMultiCheckbox"
import { Button } from "../../../../_partials/Button"
import { AutocompleteWithLazyLoading } from "../../../Admin/manage-customer/Assets/manage-assets/AutocompleteWithLazyLoading"
import DatePicker from "react-datepicker"
import { InputAdornment, TextField, makeStyles } from "@material-ui/core"
import { MdOutlineCalendarToday } from "react-icons/md"
import { checkIfTagsAreAssigned } from "../../../_redux/_helpers/ActionHelpers"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import { Collapse, IconButton } from "@material-ui/core/"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import "react-phone-input-2/lib/style.css"
import PhoneInput from "react-phone-input-2"
// import "../ManagePeople.css"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import ImageUploading from "react-images-uploading"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import { fetchDownloadURL } from "../../../_redux/managePeople/managePeopleHelpers"
import { uuidv4 } from "../../UIHelpers"
const phoneNumberRegex = /^\+\d+\s\d+$/

const EditSchema = Yup.object().shape({
	// name: Yup.string().trim().required("Name is required").min(1, "Name must not be empty"),
	firstName: Yup.string()
		.matches(/^[^\d]*$/, "First name cannot contain numbers") // Disallow numbers
		.trim()
		.required("Please insert First Name")
		.min(2, "Please insert more than 1 letter"),
	lastName: Yup.string()
		.matches(/^[^\d]*$/, "Last name cannot contain numbers") // Disallow numbers
		.trim()
		.required("Please insert Last Name")
		.min(2, "Please insert more than 1 letter"),
	company: Yup.string().nullable().required("Company is required"),
	role: Yup.string().nullable().required("Role is required"),
	email: Yup.string().email().nullable(),
	phoneNumber: Yup.string()
		//.matches(phoneNumberRegex, "Invalid phone number format. Example: +1 5551234567")
		.nullable(),
	/* phone: Yup.string()
		.test("phones", "Invalid phone number", value => {
			if (!value) {
				return true
			}
			if (isMobilePhone(value)) {
				return true
			}
			return
		})
		.nullable(), */
	idCard: Yup.string().nullable().trim().min(1, "Id Number must not be empty"),
	passport: Yup.string().nullable().trim().min(1, "Passport must not be empty"),
	driverLicense: Yup.string().nullable().trim().min(1, "Drivers License must not be empty"),
	helmetId: Yup.string()
		.nullable()
		.trim()
		.matches(/^[0-9]+$/, "Helmet ID must contain only numbers"),
	emergencyName: Yup.string().nullable().trim().min(1, "Emergency Name must not be empty"),
	emergencyNumber: Yup.string()
		.matches(phoneNumberRegex, "Invalid emergency phone number format. Example: +1 5551234567")
		.nullable(),
	supervisorName: Yup.string().nullable().trim().min(1, "Supervisor Name must not be empty"),
	supervisorNumber: Yup.string()
		.matches(phoneNumberRegex, "Invalid supervisor phone number format. Example: +1 5551234567")
		.nullable()
})

export function PersonDetailedPageForm({ onHide, initialValues, personId, personToEdit }) {
	const dispatch = useDispatch()
	const classes = useStyles()

	// Selectors
	const {
		isLoading,
		customer,
		customerId,
		siteId,
		roles,
		areas,
		vendors,
		user,
		floorPlans,
		isSuper,
		people
	} = useSelector(
		state => ({
			isLoading: state.basePage?.actionsLoading,
			customer: state.profile?.currentCustomer,
			customerId: state.profile?.currentCustomer?.id,
			siteId: state.profile?.currentSite?.id,
			roles: state.profile?.currentCustomer?.roles || [],
			areas: state.basePage?.areas
				? state.basePage?.areas.filter(val => val.geoFencing === true)
				: [],
			floorPlans: state.basePage?.floorPlans,
			vendors: state.profile?.currentCustomer?.vendors || [],
			user: state.auth?.user,
			isSuper: state.auth?.claims?.isSuper,
			people: state.basePage?.people || []
		}),
		shallowEqual
	)

	// States
	const [selectedCompany, setSelectedCompany] = useState(initialValues.company || "")
	const [selectedRole, setSelectedRole] = useState(initialValues.role || "")
	const [selectedNationality, setSelectedNationality] = useState(initialValues.nationality || "")
	const [generalInfoOpen, setGeneralInfoOpen] = useState(true)
	const [documentationInfoOpen, setDocumentationInfoOpen] = useState(true)
	const [onboardingOpen, setOnboardingOpen] = useState(false)
	const [otherContacts, setOtherContacts] = useState(false)
	const [geofencingSelectedAll, setGeofencingSelectedAll] = useState()
	const [groupedGeofencingOptions, setGroupedGeofencingOptions] = useState()
	const [countryCode, setCountryCode] = useState("")
	const [personImageFileName, setPersonImageFileName] = useState(null)
	const [newImage, setNewImage] = useState(null)

	useEffect(() => {
		if (!areas || !floorPlans) return

		// Create options array based on the association
		const groupedOptions = floorPlans.map(floorplan => {
			const associatedAreas = areas.filter(area => area.floorPlanId === floorplan.id)

			return {
				floorPlanId: floorplan.id,
				categoryName: floorplan.name, // Use floorplan name as the category name
				items: associatedAreas.map(area => ({
					value: area.id,
					name: area.name
				}))
			}
		})

		setGroupedGeofencingOptions(groupedOptions)
	}, [])

	// Set uploaded image to state to be displayed on edit person
	useEffect(() => {
		if (personToEdit && personToEdit?.pictureUrl && personToEdit?.personalData.picture) {
			setNewImage(personToEdit?.pictureUrl)

			const splitStr = personToEdit?.personalData.picture.split("PeopleImages/")
			const nameOfPicInStorage = splitStr && splitStr[1]
			nameOfPicInStorage && setPersonImageFileName(nameOfPicInStorage) // if there's an image uploaded keep the file name to allow to delete from storage
		}
		if (personToEdit && !personToEdit?.pictureUrl && personToEdit?.personalData.picture) {
			// If !personToEdit?.pictureUrl fetch the url.
			fetchDownloadURL(personToEdit?.personalData.picture).then(newUrl => {
				setNewImage(newUrl)

				const splitStr = personToEdit?.personalData.picture.split("PeopleImages/")
				const nameOfPicInStorage = splitStr && splitStr[1]
				nameOfPicInStorage && setPersonImageFileName(nameOfPicInStorage) // if there's an image uploaded keep the file name to allow to delete from storage
			})
		}
	}, [personToEdit])

	const handleGetPictureName = path => {
		if (typeof path !== "string") {
			return null // Return null if path is not a string
		}

		const splitStr = path.split("PeopleImages/")
		const nameOfPicInStorage = splitStr[1]
		return nameOfPicInStorage
	}

	// Submit handler to save form
	const onSubmit = async values => {
		console.log("site", siteId)
		const vendorFounded = vendors && vendors.find(v => v.company === values.company)
		let uuid = null

		if (values?.uuid) {
			if (initialValues?.uuid === values?.uuid) {
				uuid = values?.uuid
			} else {
				const tagsChecked = await checkIfTagsAreAssigned({ customerId, tags: values.uuid })
				if (tagsChecked.some(val => val.uuid === values?.uuid && val?.assigned)) {
					console.error("Error: Tag as been assigned by someone else.")
					dispatch({
						type: "SNACKBAR_ERROR",
						payload: "Error creating Person, please try again!"
					})
					onHide()
					return
				}
			}
			uuid = values?.uuid
		}

		// Input phone number and state code
		const phoneNumber = values.phoneNumber
		const stateCode = countryCode

		let formattedPhoneNumber

		// Check if the string starts with the state code
		if (stateCode && stateCode.length > 0 && phoneNumber.startsWith(stateCode)) {
			const phoneNumberWithoutCode = phoneNumber.slice(stateCode.length)

			formattedPhoneNumber = "+" + Number(stateCode) + " " + Number(phoneNumberWithoutCode)
		} else {
			formattedPhoneNumber = null
		}

		const randomNumberUnique = uuidv4() // create unique id for picture upload

		if (values.picture?.file) {
			var pictureToUpload = values.picture
			const name = values.picture.file?.name
			var nameWithId = name + "_" + randomNumberUnique
			var imagePath = `Customers/${customerId}/PeopleImages/${nameWithId}`
		}

		const deleteOldPic =
			handleGetPictureName(values.picture) === personImageFileName
				? false
				: nameWithId !== personImageFileName
				? true
				: !nameWithId
				? true
				: false // if user wants to upload new pic, make sure to delete old pic

		if (personId) {
			// Note: Improvement to be done if we have time. We get a Firebase get() error when uploading a new image because, the basePage.js has a function to get the image path and get the image url and add that url with the prop pictureUrl. Because the backend function is receiving and writing this path on firestore doc, and only after the response is success we upload to storage the error is because the image doesn't exist yet on storage. The way to build this correctly with more time is to upload the image before writing the path with the backend function. If response not successfull, delete uploaded image.
			dispatch(
				actions.editPerson({
					...values,
					id: personId,
					customerId,
					siteId,
					uuid: uuid,
					user,
					companyId: vendorFounded ? vendorFounded.id : null,
					company: vendorFounded ? vendorFounded?.company : null,
					phoneNumber: formattedPhoneNumber ? formattedPhoneNumber : values.phoneNumber,
					name: `${values.firstName} ${values.lastName}`,
					picture: imagePath
						? imagePath
						: personToEdit?.personalData?.picture && !personImageFileName
						? personToEdit?.personalData.picture
						: personToEdit?.personalData?.picture && !deleteOldPic
						? personToEdit?.personalData.picture
						: personToEdit?.personalData?.picture && !deleteOldPic && !values.picture
						? null
						: personToEdit?.personalData?.picture && deleteOldPic && values.picture
						? personToEdit?.personalData.picture
						: null,
					pictureToUpload: pictureToUpload || null,
					randomNumberUnique: pictureToUpload ? randomNumberUnique : null,
					personImageFileName: !pictureToUpload
						? personImageFileName
						: deleteOldPic
						? personImageFileName
						: null,
					deleteOldPic: deleteOldPic
				})
			)
				.then(() => {
					onHide() // close modal
				})

				.catch(() => {
					dispatch({
						type: "SNACKBAR_ERROR",
						payload: "Error editing Person, please try again!"
					})
				})
			return
		}
	}

	const header = (title, isOpen, setOpen) => (
		<div className="d-flex align-items-center">
			<div className="manage_header_spacing">
				<IconButton
					size="small"
					onClick={() => {
						setOpen(!isOpen)
					}}
				>
					{isOpen ? (
						<ExpandLessIcon fontSize="large" style={{ color: "#4A4A68" }} />
					) : (
						<ExpandMoreIcon fontSize="large" style={{ color: "#4A4A68" }} />
					)}
				</IconButton>
			</div>
			<span>{title}</span>
		</div>
	)

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={EditSchema}
			onSubmit={values => {
				onSubmit(values)
			}}
		>
			{({ dirty, values, setFieldValue, handleSubmit }) => {
				{
					/* const person = people.find(p => p.id === personId)
				console.log("🚀  person:", person) */
				}

				const picture = values.picture

				if (
					values.geofencing &&
					Array.isArray(values.geofencing) &&
					// values.geofencing.length === 1 &&
					values.geofencing.some(val => val === "all")
				) {
					const allValues = areas.map(val => val.id)
					setFieldValue("geofencing", allValues)
				}
				if (
					values.geofencing &&
					Array.isArray(values.geofencing) &&
					values.geofencing.some(val => val === "none")
				) {
					setFieldValue("geofencing", [])
				}

				if (values.email && values.email.includes(" ")) {
					setFieldValue("email", values.email.replace(" ", ""))
				}

				const onImageChange = imageList => {
					if (!imageList || !imageList?.[0]) return

					setNewImage(imageList?.[0]?.data_url)
					setFieldValue("picture", imageList[0])
				}

				const getPicture = () => {
					if (!newImage) {
						return `url(${toAbsoluteUrl("/svg/communication/person-blue.svg")}`
					}
					if (newImage && !values.picture) {
						return `url(${toAbsoluteUrl("/svg/communication/person-blue.svg")}`
					}

					return `url(${newImage})`
				}

				return (
					<Form
						className="form form-label-right"
						data-testid="new-person-modal_PersonDetailedPageForm"
						style={{
							padding: "10px 20px",
							borderRadius: "5px",
							background: "#FFF",
							boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.10)"
						}}
					>
						<div className="row">
							<div
								className="col"
								style={{
									color: "#444",
									fontFamily: "Poppins",
									fontSize: "20px",
									fontStyle: "normal",
									fontWeight: "600",
									lineHeight: "160%"
								}}
							>
								Patient Profile
							</div>
							<div
								className="col"
								style={{
									textAlign: "end"
								}}
							>
								<OverlayTrigger
									placement="bottom"
									overlay={<Tooltip id="firebase-messaging-tooltip">Locate</Tooltip>}
								>
									<Button
										style={{ padding: "6px" }}
										color="primaryLight"
										variant="contained"
									>
										<img
											alt="Locate"
											src="/icons/location--person.svg"
											width="22"
											height="22"
										/>
									</Button>
								</OverlayTrigger>
								<Button
									color="primary"
									variant="contained"
									text="export"
									className="ml-2"
								/>
							</div>
						</div>

						<Card style={{ boxShadow: "none" }} margin={false}>
							<CardHeader
								className="create_person_modal_title"
								padding={false}
								line={false}
								title={header("General Information", generalInfoOpen, setGeneralInfoOpen)}
							/>
							<Collapse in={generalInfoOpen} className={classes.collapse}>
								<CardBody style={{ padding: "10px 0" }}>
									<div className="form-group d-flex flex-column flex-lg-row w-100">
										<div className="col-lg-4 d-flex justify-content-center align-items-center mb-lg-0 mb-5">
											<ImageUploading
												onChange={onImageChange}
												dataURLKey="data_url"
												acceptType={["png", "jpg", "jpeg"]}
											>
												{({
													onImageUpload,
													onImageRemoveAll,
													isDragging,
													dragProps
												}) => (
													<div className="upload__image-wrapper">
														<div
															className="image-input image-input-outline"
															id="kt_profile_avatar"
															style={{
																backgroundColor: "white",
																paddingRight: "8px",
																paddingTop: "8px",
																paddingBottom: "8px"
															}}
														>
															<div
																className="image-input-wrapper"
																style={{
																	borderColor: isDragging ? "#C3C4DD" : "white",
																	backgroundImage: `${getPicture()}`,
																	backgroundColor: "#DDE9FD",
																	backgroundSize: picture
																		? "100%"
																		: newImage
																		? "100%"
																		: newImage && !values.picture
																		? "50%"
																		: "50%",
																	backgroundRepeat: "no-repeat",
																	backgroundPosition: "center",
																	cursor: "pointer",
																	height: "178px",
																	width: "178px"
																}}
																onClick={!isLoading ? onImageUpload : () => {}}
																{...dragProps}
															/>
															<label
																className={`btn btn-md btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow ${
																	isLoading ? "disabled" : ""
																}`}
																data-action="change"
																data-toggle="tooltip"
																title=""
																data-original-title="Change logo"
																onClick={!isLoading ? onImageUpload : () => {}}
															>
																<i className="fa fa-pen icon-sm text-muted"></i>
															</label>
															<span
																onClick={() => {
																	if (picture && !isLoading) {
																		onImageRemoveAll()
																		setFieldValue("picture", null)
																	}
																	if (!isLoading && newImage) {
																		onImageRemoveAll()
																		setFieldValue("picture", null)
																		setNewImage(null)
																	}
																}}
																className={`btn btn-md btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow ${
																	isLoading ? "disabled" : ""
																}`}
																data-action="remove"
																data-toggle="tooltip"
																title=""
																data-original-title="Remove logo"
															>
																<i className="ki ki-bold-close icon-xs text-muted"></i>
															</span>
														</div>
														{picture?.file?.size &&
															picture.file.size / (1024 * 1024) > 10 && (
																<span className="form-text text-danger mt-2">
																	{`Image is too big (${(
																		picture.file.size /
																		(1024 * 1024)
																	).toFixed(2)}Mb).`}
																	<br />
																	Max imagesize: 10Mb.
																</span>
															)}
														<span className="form-text text-muted mt-2">
															Allowed file types: png, jpg, jpeg.
														</span>
													</div>
												)}
											</ImageUploading>
										</div>
										<div className="col-lg-8">
											<div className="row">
												<div className="col-12 mb-5 pl-lg-3 pr-lg-3">
													<Field
														disabled={isSuper ? false : true} // access only for superAdmins
														variant="filled"
														name="firstName"
														label="First Name"
														requiredField
														size="small"
														component={Input}
														InputProps={{
															disableUnderline: true
														}}
														placeholder="Enter first name"
													/>
												</div>
												<div className="col-12 mb-5 pl-lg-3 pr-lg-3">
													<Field
														disabled={isSuper ? false : true} // access only for superAdmins
														variant="filled"
														name="lastName"
														label="Last Name"
														requiredField
														size="small"
														component={Input}
														InputProps={{
															disableUnderline: true
														}}
														placeholder="Enter last name"
													/>
												</div>
												<div className="col-12 mb-5 pl-lg-3 pr-lg-3">
													<Field
														variant="filled"
														name="email"
														label="Email"
														size="small"
														component={Input}
														InputProps={{
															disableUnderline: true
														}}
														placeholder="Enter email"
													/>
												</div>
												{!personId && (
													<div className="col-12 mb-5 pl-lg-3 pr-lg-3">
														<Field name="phone">
															{({ field, form }) => {
																return (
																	<>
																		<div
																			style={{
																				marginBottom: "10px",
																				height: "18px"
																			}}
																		>
																			<span
																				style={{
																					color: "#4A4A68",
																					fontFamily: "Poppins",
																					fontSize: "12px",
																					fontWeight: "500"
																				}}
																			>
																				Phone Number
																			</span>
																		</div>
																		<PhoneInput
																			name="phoneNumber"
																			{...field}
																			/* country={"us"} */
																			containerClass="countryCode_containerPeople"
																			inputClass="countryCode_inputClassPeople"
																			buttonClass="countryCode_buttonClassPeople"
																			dropdownClass="countryCode_dropdownClassPeople"
																			placeholder="Phone number"
																			onChange={(value, data) => {
																				form.setFieldValue("phoneNumber", value)
																				setCountryCode(data.dialCode)
																			}}
																		/>
																	</>
																)
															}}
														</Field>
													</div>
												)}

												{personId && (
													<div className="col-12">
														<Field name="phone" placeholder="Enter phone number">
															{({ field, form }) => {
																return (
																	<>
																		<div
																			style={{
																				marginBottom: "10px",
																				height: "18px"
																			}}
																		>
																			<span
																				style={{
																					color: "#4A4A68",
																					fontFamily: "Poppins",
																					fontSize: "12px",
																					fontWeight: "500"
																				}}
																			>
																				Phone Number
																			</span>
																		</div>
																		<PhoneInput
																			name="phoneNumber"
																			{...field}
																			/* country={"us"} */
																			containerClass="countryCode_containerPeople"
																			inputClass="countryCode_inputClassPeople"
																			buttonClass="countryCode_buttonClassPeople"
																			dropdownClass="countryCode_dropdownClassPeople"
																			placeholder="Phone number"
																			value={initialValues?.phoneNumber}
																			onChange={(value, data) => {
																				form.setFieldValue("phoneNumber", value)
																				setCountryCode(data.dialCode)
																			}}
																		/>
																	</>
																)
															}}
														</Field>
													</div>
												)}
											</div>
										</div>
									</div>
								</CardBody>
							</Collapse>
						</Card>
						<Card style={{ boxShadow: "none" }} margin={false}>
							<CardHeader
								className="create_person_modal_title"
								padding={false}
								line={false}
								title={header(
									"Documentation Information",
									documentationInfoOpen,
									setDocumentationInfoOpen
								)}
							/>
							<Collapse in={documentationInfoOpen} className={classes.collapse}>
								<CardBody style={{ padding: "10px 0" }}>
									<div className="form-group d-flex flex-column flex-lg-row w-100">
										<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
											<Field
												id="nationality-autocomplete"
												customColor="#F2F2F2"
												name="nationality"
												label="Nationality"
												component={Autocomplete_People}
												placeholder="Nationality"
												options={[
													...nationalities.map(val => {
														return { value: val, name: val }
													})
												]}
												value={selectedNationality}
												onChange={(event, newValue) => {
													setSelectedNationality(newValue)
													setFieldValue("nationality", newValue)
												}}
											/>
										</div>
										<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
											<div style={{ marginBottom: "10px" }}>
												<span className="new_person_date_picker_label">
													Date of Birth
												</span>
											</div>
											<DatePicker
												className="custom-datepicker"
												calendarClassName="date_picker_range_reporting"
												selected={values.birthdate}
												onChange={e => setFieldValue("birthdate", new Date(e))}
												customInput={<ExampleCustomInput />}
												dateFormat="dd/MM/yyyy"
												wrapperClassName="custom-datepicker-wrapper"
												showMonthDropdown
												showYearDropdown
												dropdownMode="select"
											/>
										</div>
									</div>
									<div className="form-group d-flex flex-column flex-lg-row w-100">
										<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3 pl-md-3 pr-md-3">
											<Field
												variant="filled"
												name="idCard"
												label="ID Number"
												size="small"
												component={Input}
												InputProps={{
													disableUnderline: true
												}}
												placeholder="Enter ID Number"
											/>
										</div>
										<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3 pl-md-3 pr-md-3">
											<Field
												variant="filled"
												name="passport"
												label="Passport"
												size="small"
												component={Input}
												InputProps={{
													disableUnderline: true
												}}
												placeholder="Enter Passport Number"
											/>
										</div>
										<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3 pl-md-3 pr-md-3">
											<Field
												variant="filled"
												name="driverLicense"
												label="Drivers License"
												size="small"
												component={Input}
												InputProps={{
													disableUnderline: true
												}}
												placeholder="Enter Drivers Licence Number"
											/>
										</div>
									</div>
								</CardBody>
							</Collapse>
						</Card>
						<Card style={{ boxShadow: "none" }} margin={false}>
							<CardHeader
								className="create_person_modal_title"
								padding={false}
								line={false}
								title={header("On-Boarding", onboardingOpen, setOnboardingOpen)}
							/>
							<Collapse in={onboardingOpen} className={classes.collapse}>
								<CardBody style={{ padding: "10px 0" }}>
									<div className="form-group d-flex flex-column flex-lg-row w-100">
										<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
											<Field
												name="tag"
												label="Tag"
												component={AutocompleteWithLazyLoading}
												onChange={value => {
													if (!value) {
														setFieldValue("uuid", null)
													} else {
														setFieldValue("uuid", value.uuid)
													}
													// setFieldValue("tag", value)
												}}
												uuid={initialValues.uuid}
												placeholder="Enter Tag"
												customHeight="49px"
												disabled={isSuper ? false : initialValues.uuid ? true : false}
											/>
										</div>
										<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
											<Field
												// disabled={personId && values.company ? true : false}
												disabled={isSuper ? false : true} // access only for superAdmins
												id="company-autocomplete"
												customColor="#F2F2F2"
												name="company"
												label="Company"
												requiredField
												component={Autocomplete_People}
												placeholder="Company"
												options={[
													...vendors.map(val => {
														return {
															value: val.company,
															name: val.company
														}
													})
												]}
												value={selectedCompany}
												onChange={(event, newValue) => {
													setSelectedCompany(newValue)
													setFieldValue("company", newValue)
												}}
											/>
										</div>
										<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
											<div style={{ marginBottom: "10px" }}>
												<span className="new_person_date_picker_label">Start Date</span>
											</div>
											<DatePicker
												className="custom-datepicker"
												// calendarClassName="date_picker_range_reporting"
												// selected={values.startDate ? values.startDate : new Date()}
												selected={values?.startDate || new Date()}
												onChange={e => setFieldValue("startDate", new Date(e))}
												customInput={<ExampleCustomInput2 />}
												dateFormat="dd/MM/yyyy"
												wrapperClassName="custom-datepicker-wrapper"
											/>
										</div>
									</div>
									<div className="form-group d-flex flex-column flex-lg-row w-100">
										<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
											<Field
												id="role-autocomplete"
												customColor="#F2F2F2"
												name="role"
												label="Role"
												component={Autocomplete_People}
												placeholder="Role"
												requiredField
												options={[
													...roles
														.filter(val => val.id)
														.map(val => {
															return { value: val.name, name: val.name }
														})
												]}
												value={selectedRole}
												onChange={(event, newValue) => {
													console.log("🚀  newValue:", newValue)
													setSelectedRole(newValue)
													setFieldValue("role", newValue)
												}}
											/>
										</div>
										<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
											<Field
												customColor="#F2F2F2"
												name="geofencing"
												label="Geofencing"
												component={SelectMultiCheckbox}
												selectAllEnabled
												// isGrouped
												// options={groupedGeofencingOptions}
												options={[
													...areas.map(val => {
														return { value: val.id, name: val.name }
													})
												]}
											/>
										</div>
										<div className="col mb-lg-0 mb-5 pl-lg-3 pr-lg-3">
											<Field
												variant="filled"
												name="helmetId"
												label="Helmet ID"
												size="small"
												newId="helmet_new_person_input"
												component={Input}
												InputProps={{
													disableUnderline: true
												}}
												placeholder="Enter Helmet ID"
											/>
										</div>
									</div>
								</CardBody>
							</Collapse>
						</Card>
						<Card style={{ boxShadow: "none" }} margin={false}>
							<CardHeader
								className="create_person_modal_title"
								padding={false}
								line={false}
								title={header("Other Contacts", otherContacts, setOtherContacts)}
							/>
							<Collapse in={otherContacts} className={classes.collapse}>
								<CardBody style={{ padding: "10px 0" }}>
									<div className="form-group row">
										<div className="col">
											<Field
												variant="filled"
												name="emergencyName"
												label="Contact Name"
												component={Input}
												InputProps={{
													disableUnderline: true
												}}
												placeholder="Enter Name"
											/>
										</div>
										<div className="col">
											<Field
												variant="filled"
												name="emergencyNumber"
												label="Contact Phone Number"
												component={Input}
												InputProps={{
													disableUnderline: true
												}}
												placeholder="Enter Phone Number"
											/>
										</div>
									</div>
									<div className="form-group row">
										<div className="col">
											<Field
												variant="filled"
												name="supervisorName"
												label="Supervisor Name"
												component={Input}
												InputProps={{
													disableUnderline: true
												}}
												placeholder="Enter Name"
											/>
										</div>
										<div className="col" style={{ marginBottom: "2rem" }}>
											<Field
												variant="filled"
												name="supervisorNumber"
												label="Supervisor Phone Number"
												component={Input}
												InputProps={{
													disableUnderline: true
												}}
												placeholder="Enter Phone Number"
											/>
										</div>
									</div>
								</CardBody>
							</Collapse>
						</Card>

						<Button
							variant="contained"
							color="primary"
							style={{ width: "100%", marginTop: "2.25rem", marginBottom: "1.25rem" }}
							disabled={!dirty || isLoading}
							text="SAVE PERSON"
							onClick={handleSubmit}
							data-testid="new-person-modal_handleSubmit"
						/>
					</Form>
				)
			}}
		</Formik>
	)
}

const useStyles = makeStyles(() => ({
	datePicker: {
		// marginTop: "2px",
		// backgroundColor: "#FFFFFF",
		background: "#F2F2F2",
		borderRadius: "5px",
		margin: 0,
		padding: "0 12px",
		height: "44px",
		"&:hover": {
			cursor: "pointer",
			border: "1px solid grey"
		},
		"& .MuiInputBase-root": {
			cursor: "pointer",
			height: "38px"
		},
		"&:hover .MuiInputBase-input": {
			cursor: "pointer"
		}
	},
	datePicker2: {
		// marginTop: "2px",
		backgroundColor: "#FFFFFF",
		borderRadius: "5px",
		margin: 0,
		padding: "0 12px",
		height: "44px",
		"&:hover": {
			cursor: "pointer",
			border: "1px solid grey"
		},
		"& .MuiInputBase-root": {
			cursor: "pointer",
			height: "44px"
		},
		"&:hover .MuiInputBase-input": {
			cursor: "pointer"
		}
	},
	collapse: {
		borderBottom: "1px solid #E2E6EA",
		visibility: "visible",
		"& .MuiCollapse-hidden": {
			visibility: "visible"
		}
	}
}))

const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
	const classes = useStyles()

	return (
		<div className="row" style={{ margin: "auto" }} onClick={onClick} ref={ref}>
			<TextField
				className={classes.datePicker}
				fullWidth
				value={value}
				// style={{ backgroundColor: "#F2F2F2" }}
				// style={{ backgroundColor: "#F2F2F2",  }}
				InputProps={{
					readOnly: true,
					disableUnderline: true,
					endAdornment: (
						<InputAdornment position="end">
							<MdOutlineCalendarToday style={{ fontSize: "1.4rem", color: "#8C8CA1" }} />
						</InputAdornment>
					)
				}}
			/>
		</div>
	)
})
ExampleCustomInput.displayName = "ExampleCustomInput"

const ExampleCustomInput2 = forwardRef(({ value, onClick }, ref) => {
	const classes = useStyles()

	return (
		<div className="row" style={{ margin: "auto" }} onClick={onClick} ref={ref}>
			<TextField
				className={classes.datePicker2}
				fullWidth
				value={value}
				style={{ backgroundColor: "#F2F2F2" }}
				InputProps={{
					readOnly: true,
					disableUnderline: true,
					endAdornment: (
						<InputAdornment position="end">
							<MdOutlineCalendarToday style={{ fontSize: "1.4rem", color: "#8C8CA1" }} />
						</InputAdornment>
					)
				}}
			/>
		</div>
	)
})
ExampleCustomInput2.displayName = "ExampleCustomInput"

const nationalities = [
	"Afghan",
	"Albanian",
	"Algerian",
	"American",
	"Andorran",
	"Angolan",
	"Antiguans",
	"Argentinean",
	"Armenian",
	"Australian",
	"Austrian",
	"Azerbaijani",
	"Bahamian",
	"Bahraini",
	"Bangladeshi",
	"Barbadian",
	"Barbudans",
	"Batswana",
	"Belarusian",
	"Belgian",
	"Belizean",
	"Beninese",
	"Bhutanese",
	"Bolivian",
	"Bosnian",
	"Brazilian",
	"British",
	"Bruneian",
	"Bulgarian",
	"Burkinabe",
	"Burmese",
	"Burundian",
	"Cambodian",
	"Cameroonian",
	"Canadian",
	"Cape Verdean",
	"Central African",
	"Chadian",
	"Chilean",
	"Chinese",
	"Colombian",
	"Comoran",
	"Congolese",
	"Costa Rican",
	"Croatian",
	"Cuban",
	"Cypriot",
	"Czech",
	"Danish",
	"Djibouti",
	"Dominican",
	"Dutch",
	"East Timorese",
	"Ecuadorean",
	"Egyptian",
	"Emirian",
	"Equatorial Guinean",
	"Eritrean",
	"Estonian",
	"Ethiopian",
	"Fijian",
	"Filipino",
	"Finnish",
	"French",
	"Gabonese",
	"Gambian",
	"Georgian",
	"German",
	"Ghanaian",
	"Greek",
	"Grenadian",
	"Guatemalan",
	"Guinea-Bissauan",
	"Guinean",
	"Guyanese",
	"Haitian",
	"Herzegovinian",
	"Honduran",
	"Hungarian",
	"I-Kiribati",
	"Icelander",
	"Indian",
	"Indonesian",
	"Iranian",
	"Iraqi",
	"Irish",
	"Israeli",
	"Italian",
	"Ivorian",
	"Jamaican",
	"Japanese",
	"Jordanian",
	"Kazakhstani",
	"Kenyan",
	"Kittian and Nevisian",
	"Kuwaiti",
	"Kyrgyz",
	"Laotian",
	"Latvian",
	"Lebanese",
	"Liberian",
	"Libyan",
	"Liechtensteiner",
	"Lithuanian",
	"Luxembourger",
	"Macedonian",
	"Malagasy",
	"Malawian",
	"Malaysian",
	"Maldivan",
	"Malian",
	"Maltese",
	"Marshallese",
	"Mauritanian",
	"Mauritian",
	"Mexican",
	"Micronesian",
	"Moldovan",
	"Monacan",
	"Mongolian",
	"Moroccan",
	"Mosotho",
	"Motswana",
	"Mozambican",
	"Namibian",
	"Nauruan",
	"Nepali",
	"New Zealander",
	"Nicaraguan",
	"Nigerian",
	"Nigerien",
	"North Korean",
	"Northern Irish",
	"Norwegian",
	"Omani",
	"Pakistani",
	"Palauan",
	"Panamanian",
	"Papua New Guinean",
	"Paraguayan",
	"Peruvian",
	"Polish",
	"Portuguese",
	"Qatari",
	"Romanian",
	"Russian",
	"Rwandan",
	"Saint Lucian",
	"Salvadoran",
	"Samoan",
	"San Marinese",
	"Sao Tomean",
	"Saudi",
	"Scottish",
	"Senegalese",
	"Serbian",
	"Seychellois",
	"Sierra Leonean",
	"Singaporean",
	"Slovakian",
	"Slovenian",
	"Solomon Islander",
	"Somali",
	"South African",
	"South Korean",
	"Spanish",
	"Sri Lankan",
	"Sudanese",
	"Surinamer",
	"Swazi",
	"Swedish",
	"Swiss",
	"Syrian",
	"Taiwanese",
	"Tajik",
	"Tanzanian",
	"Thai",
	"Togolese",
	"Tongan",
	"Trinidadian/Tobagonian",
	"Tunisian",
	"Turkish",
	"Tuvaluan",
	"Ugandan",
	"Ukrainian",
	"Uruguayan",
	"Uzbekistani",
	"Venezuelan",
	"Vietnamese",
	"Welsh",
	"Yemenite",
	"Zambian",
	"Zimbabwean"
]
