import React, { useMemo, useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useUIContext } from "../UIContext"
import * as actions from "../../../_redux/admin/inventory/InventoryActions"
import Alert from "@material-ui/lab/Alert"
import { makeStyles } from "@material-ui/core/styles"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { Button } from "../../../../_partials/Button"
import { Autocomplete_People } from "../../../../_partials/inputs/Autocomplete_PeopleTable"
import { Input } from "../../../../_partials/inputs/Input"
import List from "@material-ui/core/List"
import { ListItemIcon } from "@material-ui/core"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"
import ViewListIcon from "@material-ui/icons/ViewList"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import { startButtonProgress, finishButtonProgress } from "../../utils/utils"

const EditSchema = Yup.object().shape({
	// firmware: Yup.string().trim().required("Name is required").min(1, "Name must not be empty"),
	firmware: Yup.string().nullable().trim().min(1, "Firmware must not be empty"),
	vendor: Yup.string().nullable().trim().min(1, "Firmware Vendor must not be empty"),
	model: Yup.string().nullable().trim().min(1, "Model must not be empty"),
	notes: Yup.string().nullable().trim().min(1, "Notes must not be empty")
})

const initialValues = {
	firmware: "",
	vendor: "",
	model: "",
	notes: ""
}

const useStyles = makeStyles(theme => ({
	alert: {
		width: "100%",
		"& .MuiAlert-message": {
			alignSelf: "center"
		}
	},
	nested: {
		paddingLeft: "4rem",
		cursor: "default"
	}
}))

export function EditInventoryModalForm({ onHide, saving, setSaving }) {
	const dispatch = useDispatch()
	const classes = useStyles()

	// ━━━━━━━━━━━━━  useUIContext  ━━━━━━━━━━━━━ \\
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			ids: UIContext.idsInventoryNodes,
			setIds: UIContext.setIdsInventoryNodes,
			technology: UIContext.technology,
			openEditInventoryNodesModal: UIContext.openEditInventoryNodesModal
		}
	}, [UIContext])
	// ━━━━━━━━━━━━━  useSelector  ━━━━━━━━━━━━━ \\
	const [selectedModel, setSelectedModel] = useState("")
	const [selectedFirmware, setSelectedFirmware] = useState("")
	const [selectedVendor, setSelectedVendor] = useState("")
	const [hasNotes, setHasNotes] = useState(false)
	const [modelList, setModelList] = useState([])
	const [firmwareList, setFirmwareList] = useState([])
	const [firmwareVendorList, setFirmwareVendorList] = useState([])
	const [open, setOpen] = useState(false)

	// ━━━━━━━━━━━━━  useSelector  ━━━━━━━━━━━━━ \\
	const { modelsInventory, firmwaresInventory, listLoading, user, customers, nodesInventory } =
		useSelector(
			state => ({
				modelsInventory: state.inventory.modelsInventory || [],
				firmwaresInventory: state.inventory.firmwaresInventory,
				listLoading: state.inventory.listLoading,
				user: state.auth?.user,
				customers: state?.inventory?.customers,
				nodesInventory: state?.inventory?.nodesInventory
			}),
			shallowEqual
		)
	// ━━━━━━━━━━━━━  useEffect  ━━━━━━━━━━━━━ \\
	// Effect 1: Update model list
	useEffect(() => {
		// console.log("Effect 1")

		if (!modelsInventory || !UIProps.technology || modelsInventory.length <= 0) return

		const listForModel = modelsInventory
		setModelList(listForModel)
	}, [modelsInventory, UIProps.technology])

	// Effect 2: Update firmware list based on selected model
	useEffect(() => {
		if (!selectedModel || !UIProps.technology || !firmwaresInventory) {
			setSelectedVendor("")
			setSelectedFirmware("")
			return
		}
		// console.log("after Effect 2")

		const listForFirmwareVendor = firmwaresInventory.filter(
			firmware => firmware.modelId === selectedModel.id
		)

		// Create a Set to store unique vendor names
		const uniqueVendors = new Set()

		const filteredFirmwareList =
			listForFirmwareVendor &&
			listForFirmwareVendor.filter(firmware => {
				if (!uniqueVendors.has(firmware.vendor)) {
					uniqueVendors.add(firmware.vendor)
					return true
				}
				return false
			})

		setFirmwareVendorList(filteredFirmwareList)

		// Reset selected vendor if it's not available in the new firmware list
		if (
			filteredFirmwareList &&
			filteredFirmwareList.some(firmware => firmware.vendor === "Crowdkeep")
		) {
			setSelectedVendor("Crowdkeep")
		} else {
			setSelectedVendor("")
			setSelectedFirmware("")
		}
	}, [selectedModel])

	// Effect 3: Filter and select the latest firmware version based on selected vendor
	useEffect(() => {
		// console.log("Effect 3")
		if (
			!selectedVendor ||
			!firmwareVendorList ||
			!firmwaresInventory ||
			firmwaresInventory.length <= 0
		) {
			setSelectedFirmware("")
			return
		}

		// Filter firmware list based on selected vendor
		const filteredFirmwareList = firmwaresInventory.filter(
			firmware => firmware.vendor === selectedVendor
		)

		// Sort firmware list based on version numbers
		const sortedFirmwareList =
			filteredFirmwareList &&
			filteredFirmwareList.length > 0 &&
			filteredFirmwareList.sort((a, b) => {
				const versionToNumber = version => Number(version.replace(/\./g, ""))
				return versionToNumber(a.version) > versionToNumber(b.version) ? -1 : 1
			})

		// Select the latest firmware version
		if (sortedFirmwareList.length > 0) {
			setSelectedFirmware(sortedFirmwareList[0].version)
		} else setSelectedFirmware("")

		// Update firmware list state
		setFirmwareList(sortedFirmwareList)
	}, [selectedVendor])

	const onSubmit = async values => {
		const { notes } = values

		if (
			!UIProps.ids ||
			!UIProps.technology ||
			!user ||
			!firmwaresInventory ||
			firmwaresInventory.length <= 0
		)
			return

		// Find the firmware object matching the selected version and vendor
		const firmwareObject = firmwaresInventory.find(
			firm => firm.version === selectedFirmware && firm.vendor === selectedVendor
		)

		// Extract the ID from the firmware object
		const firmwareId = firmwareObject?.id && firmwareObject.id

		if (!firmwareId || !selectedModel.id) return

		setSaving(true)
		// Starts the change of the saving UI button like progress bar
		const buttonToProgressEl = document.getElementById("buttonToProgress_editPoi")
		buttonToProgressEl &&
			startButtonProgress(buttonToProgressEl, "button__progress_editPoi", "button__text_editPoi")

		dispatch(
			actions.editNodes({
				ids: UIProps.ids,
				modelId: selectedModel.id,
				firmwareId: firmwareId,
				notes: notes,
				technology: UIProps.technology,
				callersUserId: user.id,
				onHide
			})
		)
			.then(() => {
				setSaving(false)
				onHide()
			})
			.catch(error => {
				console.error("Error saving:", error) // Handle error
				setSaving(false) // Reset saving state on error
			})
	}

	React.useEffect(() => {
		if (!selectedModel) return

		console.log("🕵🏻‍♂️ ~ useEffect ~ selectedModel:", selectedModel)
	}, [selectedModel])

	const handleClick = () => {
		setOpen(!open)
	}

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={EditSchema}
			onSubmit={values => {
				onSubmit(values)
			}}
			data-testid="EditInventoryModalForm-Formik"
		>
			{({ setFieldValue, handleSubmit, dirty, values }) => {
				return (
					<Form className="form form-label-right" data-testid="EditInventoryModalForm">
						<div className="form-group row mb-5">
							<div className="col">
								{UIProps.ids && UIProps.ids.length >= 0 && (
									<Alert className={classes.alert} severity="info">
										Attention: You are currently editing{" "}
										<span style={{ fontWeight: "bold" }}>{UIProps.ids.length}</span> rows.
									</Alert>
								)}
							</div>
						</div>

						<div className="mb-5">
							{/* com collapse com span com o nome e o uuid */}
							<List
								aria-labelledby="nested-list-subheader"
								// className={classes.root}
							>
								<ListItem button onClick={handleClick}>
									<ListItemIcon>
										<ViewListIcon />
									</ListItemIcon>
									<ListItemText primary="List of nodes to edit" />
									{open ? <ExpandLess /> : <ExpandMore />}
								</ListItem>
								<Collapse
									in={open}
									timeout="auto"
									unmountOnExit
									style={{
										maxHeight: "190px",
										overflowX: "scroll"
									}}
								>
									<List component="div" disablePadding>
										{nodesInventory &&
											nodesInventory.length > 0 &&
											nodesInventory.map(node => {
												if (
													UIProps.ids &&
													UIProps.ids.length > 0 &&
													UIProps.ids.includes(node?.id)
												) {
													return (
														<ListItem
															key={node?.id}
															button
															className={classes.nested}
														>
															<ListItemIcon>
																<SVG
																	style={{
																		fill: "#1C1C3B"
																	}}
																	src={toAbsoluteUrl(
																		"/media/svg/icons/General/cube.svg"
																	)}
																/>
															</ListItemIcon>
															<ListItemText
																primary={node?.id}
																// secondary={`Current customer: ${
																// 	node?.customerId ? node?.customerId : "none"
																// }`}
																secondary={`Current customer: ${
																	node?.customerId
																		? (customers &&
																				customers.find(
																					customer =>
																						customer.id === node.customerId
																				)?.name) ||
																		  "none"
																		: "none"
																}`}
															/>
														</ListItem>
													)
												}
												return null // If the id doesn't match, return null
											})}
									</List>
								</Collapse>
							</List>
						</div>

						<div className="form-group row mb-5">
							<div className="col">
								<Field
									id="model-autocomplete"
									customColor="#ECF1F4"
									name="model"
									label="Model"
									disabled={saving}
									component={Autocomplete_People}
									placeholder="Model"
									options={
										modelList && modelList.length > 0
											? modelList.map(val => {
													const { name, vendor, battery, features, id } = val

													// Create an array of feature strings
													const featureStrings = Object.entries(features).map(
														([key, value]) => `${key}: ${value}`
													)
													const featuresText = featureStrings.join(", ")

													const tooltipText = `Battery: ${battery}, Features: ${featuresText}`

													return {
														value: name,
														name,
														group: vendor,
														tooltip: tooltipText,
														id: id
													}
											  })
											: []
									}
									grouped={true}
									value={selectedModel}
									onChange={(event, newValue) => {
										if (!newValue) {
											setSelectedModel("")
											setFieldValue("model", "")
										} else {
											setSelectedModel(newValue)
											setFieldValue("model", newValue)
										}
									}}
								/>
							</div>
						</div>
						<div className="form-group row mb-5">
							<div className="col-6">
								<Field
									id="vendor-autocomplete"
									customColor="#ECF1F4"
									name="vendor"
									label="Firmware Vendor"
									disabled={!selectedModel || saving}
									component={Autocomplete_People}
									placeholder="Firmware Vendor"
									options={
										firmwareVendorList && firmwareVendorList.length > 0
											? firmwareVendorList.map(val => {
													const { vendor } = val
													return { value: vendor, name: vendor }
											  })
											: []
									}
									value={selectedVendor}
									onChange={(event, newValue) => {
										if (!newValue) {
											setSelectedVendor("")
											setFieldValue("vendor", "")
										} else {
											setSelectedVendor(newValue)
											setFieldValue("vendor", newValue)
										}
									}}
								/>
							</div>
							<div className="col-6">
								<Field
									id="firmware-autocomplete"
									customColor="#ECF1F4"
									name="firmware"
									label="Firmware"
									disabled={!selectedVendor || saving}
									component={Autocomplete_People}
									placeholder="Firmware"
									options={
										firmwareList && firmwareList.length > 0
											? firmwareList.map(val => {
													return { value: val.id, name: val.version }
											  })
											: []
									}
									value={selectedFirmware}
									onChange={(event, newValue) => {
										if (!newValue) {
											setSelectedFirmware("")
											setFieldValue("firmware", "")
										} else {
											setSelectedFirmware(newValue)
											setFieldValue("firmware", newValue)
										}
									}}
								/>
							</div>
						</div>
						<div className="form-group row mb-5">
							<div className="col" style={{ marginBottom: "2rem" }}>
								<Field
									variant="filled"
									name="notes"
									label="Notes"
									disabled={saving}
									multiline
									rows={4} // Adjust the number of rows as needed
									component={Input}
									InputProps={{
										disableUnderline: true,
										style: { height: "auto", padding: "6px 12px" }
									}}
									data-testid="Formik_Form_Notes"
								/>
							</div>
						</div>
						<Button
							variant="contained"
							disabled={saving || (!selectedFirmware && !hasNotes)}
							data-testid="delete-button"
							style={{
								width: "100%",
								height: "35px",
								marginRight: "10px",
								fontWeight: "700",
								backgroundColor: "#3077d3",
								color: "#f4f4f4"
							}}
							// text="Save"
							onClick={() => {
								handleSubmit()
							}}
							className="ml-2"
							id="buttonToProgress_editPoi"
							data-progress-text="Saving..."
							data-complete-text="Save complete."
						>
							<div className="button__progress_editPoi"></div>
							<span className="button__text_editPoi">Save</span>
						</Button>
					</Form>
				)
			}}
		</Formik>
	)
}

// Function to compare two version numbers
const compareVersions = (versionA, versionB) => {
	const partsA = versionA.split(".").map(Number)
	const partsB = versionB.split(".").map(Number)

	for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
		const partA = partsA[i] || 0
		const partB = partsB[i] || 0

		if (partA < partB) return -1
		if (partA > partB) return 1
	}

	return 0 // Versions are equal
}
