import imageCompression from "browser-image-compression"
import { storage } from "../../../../firebase"

const storageRef = storage.ref()

// function to fetch the download URL
export async function fetchDownloadURL(path) {
	try {
		const url = await storage.ref(path).getDownloadURL()
		if (url) {
			return url
		}
		return null
	} catch (error) {
		console.log("error fetching download url.")
		// console.error(error)
		// throw error // Rethrow the error to handle it in the component
	}
}

// ━━━━━━━━ Delete from storage handler
export const handleDeleteFromStorageSinglePicture = async (customerId, fileName) => {
	if (!customerId || !fileName) {
		console.log("Invalid customer ID or file name.")
		return
	}

	const fileRef = storage.ref(`Customers/${customerId}/PeopleImages/${fileName}`)

	try {
		// Check if the file exists by attempting to retrieve its metadata
		await fileRef.getMetadata()

		// If successful, it means the file exists, so proceed to delete it
		await fileRef.delete()

		// console.log(`🛑 🛑 🛑 File deleted from storage - ${fileName}`)
	} catch (error) {
		if (error.code === "storage/object-not-found") {
			console.log(`File not found: ${fileName}`)
		} else {
			console.error(`Error deleting file: ${fileName}`, error)
		}
	}
}

// Compress and upload to storage
export const compressUploadImage = async (customerId, image, uniqueId) => {
	var options = {
		maxSizeMB: 20,
		maxWidthOrHeight: 1920,
		useWebWorker: true
	}

	return new Promise(function (resolve, reject) {
		imageCompression(image.file, options).then(async function (compressedFile) {
			const imagePath = `Customers/${customerId}/PeopleImages/${
				compressedFile.name + "_" + uniqueId
			}`

			const uploadTask = storageRef.child(imagePath).put(compressedFile)

			uploadTask.on(
				"state_changed",
				snapshot => {},
				error => {
					console.log(error, "error")
					reject(error) // Reject on error
				},
				() => {
					uploadTask.snapshot.ref.getDownloadURL().then(
						urlDownloaded => {
							resolve(urlDownloaded) // Resolve with imagePath
						},
						error => {
							console.log(error, "error")
							reject(error) // Reject on error
						}
					)
				}
			)
		})
	})
}
