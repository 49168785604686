import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
	listLoading: false,
	actionsLoading: false,
	allGroups: [],
	lastError: null
}

export const callTypes = {
	list: "list",
	action: "action"
}

export const manageGroupsSlice = createSlice({
	name: "manageGroups",
	initialState: initialProductsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		customerPeopleFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.allPeople = action.payload
		},
		groupsFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.allGroups = action.payload
		},
		groupCreated: (state, action) => {
			state.listLoading = false
			state.error = null

			const newGroupSet = [...state.allGroups, action.payload]

			state.allGroups = newGroupSet
		},
		groupsDeleted: (state, action) => {
			state.listLoading = false
			state.error = null

			const oldGroups = state.allGroups || []

			if (action.payload) {
				const newGroups = oldGroups.filter(group => !action.payload.includes(group.id))

				state.allGroups = newGroups
			}
		},
		groupDisabled: (state, action) => {
			state.listLoading = false
			state.error = null
			const updatedGroup = state.allGroups.map(group => {
				if (action.payload.includes(group.id)) {
					return {
						...group,
						disabled: true
					}
				} else {
					return group
				}
			})
			// Update the state with the updated nodes
			state.allGroups = updatedGroup
		},
		groupEnabled: (state, action) => {
			state.listLoading = false
			state.error = null
			const updatedGroup = state.allGroups.map(group => {
				if (action.payload.includes(group.id)) {
					return {
						...group,
						disabled: false
					}
				} else {
					return group
				}
			})
			// Update the state with the updated nodes
			state.allGroups = updatedGroup
		},
		groupUpdated: (state, action) => {
			state.listLoading = false
			state.error = null
			state.allGroups =
				state.allGroups &&
				state.allGroups.map(entity => {
					if (entity.id === action.payload.id)
						return {
							name: entity.name,
							...action.payload
						}
					return entity
				})
		}
	}
})
