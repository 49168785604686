import React, { useState } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { makeStyles, ButtonGroup, SvgIcon } from "@material-ui/core"
import { Button } from "../../../_partials/Button"

const TabsReporting = ({ pathname }) => {
	const classes = useStyles()

	const location = useLocation()
	const history = useHistory()

	const handleNavigation = path => {
		history.push(path)
	}

	return (
		<div className="d-flex justify-content-end" style={{ margin: "2rem 0 1rem 0" }}>
			<ButtonGroup>
				<Button
					onClick={() => handleNavigation("/reporting/people/summary")}
					variant={
						location.pathname === "/reporting/people/summary" ? "contained" : "outlined"
					}
					color="primary"
					startIcon={
						<TimesheetIcon1
							textActiveColor={
								location.pathname === "/reporting/people/summary" ? "#FFFFFF" : "#8C8CA1"
							}
						/>
					}
					style={{
						fontSize: "14px",
						fontWeight: 700,
						color: location.pathname === "/reporting/people/summary" ? "#FFFFFF" : "#8C8CA1",
						borderColor: location.pathname !== "/reporting/people/summary" && "#8C8CA1"
					}}
				>
					SUMMARY
				</Button>
				<Button
					onClick={() => handleNavigation("/reporting/people/timesheets")}
					variant={
						location.pathname === "/reporting/people/timesheets" ? "contained" : "outlined"
					}
					color="primary"
					startIcon={
						<TimesheetIcon2
							textActiveColor={
								location.pathname === "/reporting/people/timesheets" ? "#FFFFFF" : "#8C8CA1"
							}
						/>
					}
					style={{
						fontSize: "14px",
						fontWeight: 700,
						color:
							location.pathname === "/reporting/people/timesheets" ? "#FFFFFF" : "#8C8CA1",
						borderColor: location.pathname !== "/reporting/people/timesheets" && "#8C8CA1"
					}}
				>
					TIMESHEET
				</Button>
			</ButtonGroup>
		</div>
	)
}

export default TabsReporting

const TimesheetIcon1 = ({ textActiveColor }) => {
	return (
		<SvgIcon>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="23"
				height="23"
				viewBox="0 0 23 23"
				fill="none"
			>
				<path
					d="M15.625 3.54712H17.6875C18.0522 3.54712 18.4019 3.69198 18.6598 3.94985C18.9176 4.20771 19.0625 4.55745 19.0625 4.92212V19.3596C19.0625 19.7243 18.9176 20.074 18.6598 20.3319C18.4019 20.5898 18.0522 20.7346 17.6875 20.7346H5.3125C4.94783 20.7346 4.59809 20.5898 4.34023 20.3319C4.08237 20.074 3.9375 19.7243 3.9375 19.3596V4.92212C3.9375 4.55745 4.08237 4.20771 4.34023 3.94985C4.59809 3.69198 4.94783 3.54712 5.3125 3.54712H7.375V2.85962C7.375 2.49495 7.51987 2.14521 7.77773 1.88735C8.03559 1.62948 8.38533 1.48462 8.75 1.48462H14.25C14.6147 1.48462 14.9644 1.62948 15.2223 1.88735C15.4801 2.14521 15.625 2.49495 15.625 2.85962V3.54712ZM14.25 2.85962H8.75V5.60962H14.25V2.85962ZM5.3125 19.3596H17.6875V4.92212H15.625V6.98462H7.375V4.92212H5.3125V19.3596ZM7.375 9.04712H15.625V10.4221H7.375V9.04712ZM7.375 12.4846H12.875V13.8596H7.375V12.4846ZM10.8125 15.9221H7.375V17.2971H10.8125V15.9221Z"
					fill={textActiveColor}
				/>
			</svg>
		</SvgIcon>
	)
}

const TimesheetIcon2 = ({ textActiveColor }) => (
	<SvgIcon>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="23"
			viewBox="0 0 22 23"
			fill="none"
		>
			<g>
				<path
					d="M4.125 20.7346H9.625V19.3596H4.125V2.85962H15.125V9.73462H16.5V2.85962C16.4996 2.49506 16.3546 2.14555 16.0968 1.88777C15.8391 1.62999 15.4896 1.485 15.125 1.48462H4.125C3.76044 1.485 3.41093 1.62999 3.15315 1.88777C2.89537 2.14555 2.75038 2.49506 2.75 2.85962V19.3596C2.75038 19.7242 2.89537 20.0737 3.15315 20.3315C3.41093 20.5892 3.76044 20.7342 4.125 20.7346ZM13.75 4.23462H5.5V5.60962H13.75V4.23462ZM9.625 9.73462H5.5V11.1096H9.625V9.73462ZM5.5 6.98462H13.75V8.35962H5.5V6.98462ZM9.625 16.6096H5.5V17.9846H9.625V16.6096Z"
					fill={textActiveColor}
				/>
				<path
					d="M16.042 22.1096C14.9542 22.1096 13.8908 21.7871 12.9864 21.1827C12.0819 20.5784 11.3769 19.7194 10.9607 18.7144C10.5444 17.7094 10.4355 16.6035 10.6477 15.5366C10.8599 14.4697 11.3837 13.4897 12.1529 12.7205C12.9221 11.9513 13.9021 11.4275 14.969 11.2153C16.0359 11.0031 17.1418 11.112 18.1468 11.5283C19.1517 11.9446 20.0107 12.6495 20.6151 13.554C21.2194 14.4585 21.542 15.5218 21.542 16.6096C21.542 18.0683 20.9625 19.4673 19.9311 20.4987C18.8996 21.5302 17.5007 22.1096 16.042 22.1096ZM16.042 12.4846C15.2261 12.4846 14.4286 12.7265 13.7503 13.1798C13.0719 13.6331 12.5432 14.2773 12.231 15.0311C11.9188 15.7848 11.8371 16.6142 11.9963 17.4144C12.1554 18.2145 12.5483 18.9495 13.1252 19.5264C13.7021 20.1033 14.4371 20.4962 15.2372 20.6554C16.0374 20.8145 16.8668 20.7328 17.6206 20.4206C18.3743 20.1084 19.0185 19.5797 19.4718 18.9013C19.9251 18.223 20.167 17.4255 20.167 16.6096C20.167 15.5156 19.7324 14.4664 18.9588 13.6928C18.1852 12.9192 17.136 12.4846 16.042 12.4846Z"
					fill={textActiveColor}
				/>
				<path
					d="M16.9056 18.6721L15.125 16.8915V13.8596H16.5V16.3277L17.875 17.7027L16.9056 18.6721Z"
					fill={textActiveColor}
				/>
			</g>
			<defs>
				<clipPath id="clip0_12075_135793">
					<rect width="22" height="22" fill="white" transform="translate(0 0.109619)" />
				</clipPath>
			</defs>
		</svg>
	</SvgIcon>
)

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		width: "100%",
		backgroundColor: theme.palette.background.paper
	},
	outlinedPrimary: {
		borderColor: "#8C8CA1",
		"&:hover": {
			borderColor: "#505063" // Border color on hover
		}
	}
}))
