import React, { useMemo } from "react"
import objectPath from "object-path"
import SVG from "react-inlinesvg"
import { SvgIcon } from "@material-ui/core"
import { useHtmlClassService } from "../../_core/MetronicLayout"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { shallowEqual, useSelector } from "react-redux"
import "./Brand.css"

export function Brand({ handleOpenDrawer, handleHideDrawer, mainDrawerOpened, matches }) {
	const uiService = useHtmlClassService()

	const layoutProps = useMemo(() => {
		return {
			brandClasses: uiService.getClasses("brand", true),
			asideSelfMinimizeToggle: objectPath.get(uiService.config, "aside.self.minimize.toggle"),
			headerLogo: uiService.getLogo(),
			headerStickyLogo: uiService.getStickyLogo()
		}
	}, [uiService])

	const currentCustomer = useSelector(state => state.profile.currentCustomer, shallowEqual)

	return (
		<>
			{/* begin::Brand */}
			<div
				style={{
					padding: "0 16px",
					marginTop: "1rem",
					alignSelf: !matches && "center"
				}}
				className={`brand flex-column-auto ${layoutProps.brandClasses}`}
				id="kt_brand"
			>
				{/* begin::Logo */}
				{/*<Link to="" className="brand-logo">
          <img alt="logo" src={layoutProps.headerLogo}/>
        </Link>*/}

				{matches && mainDrawerOpened && (
					<div className="brand-text">
						<span className="svg-icon svg-icon-xl mr-4">
							<SVG src={toAbsoluteUrl("/media/svg/icons/Files/Crowdkeep-logo.svg")} />
						</span>
						<span
							style={{
								fontSize: "16px",
								fontStyle: "normal",
								fontWeight: "700",
								lineHeight: "160%"
							}}
							className="to-hide-text"
						>
							{currentCustomer?.customerInfo?.name}
						</span>
					</div>
				)}
				{/* end::Logo */}
				{layoutProps.asideSelfMinimizeToggle && (
					<>
						{/* begin::Toggle */}
						<button
							className="brand-toggle btn btn-sm px-0"
							id="kt_aside_toggle"
							onClick={() => {
								if (!matches) {
									handleHideDrawer()
									return
								}
								handleOpenDrawer()
							}}
						>
							<span className="svg-icon svg-icon-xl">
								<SVG
									src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}
								/>
							</span>
						</button>
						{/* end::Toolbar */}
					</>
				)}
			</div>
			{/* end::Brand */}
		</>
	)
}
