import React, { useEffect, useState, useMemo } from "react"
import {
	makeStyles,
	MenuItem,
	FormHelperText,
	FormControl,
	Select as SelectMUI,
	Checkbox,
	ListItemText
} from "@material-ui/core"
import SVG from "react-inlinesvg"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import { useUIContext } from "../../../UIContext"

const useStyles = makeStyles(theme => ({
	formControl: {
		width: "100%",
		marginTop: "2px"
	},
	select: {
		height: "45px",
		width: "100%",
		borderRadius: "4px",
		overflow: "hidden",
		"& .MuiSelect-filled": {
			backgroundColor: "#ECF1F4",
			border: 0
		},
		"& .MuiSelect-root": {
			padding: "16px 12px"
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		},
		"& .MuiInput-underline:before": {
			borderBottom: "none"
		},
		"& .MuiInput-underline:after": {
			borderBottom: "none"
		},
		"&.MuiFilledInput-underline:before": {
			borderBottom: "none"
		},
		"&.MuiFilledInput-underline:after": {
			borderBottom: "none"
		}
	},
	filled: {
		backgroundColor: "#ECF1F4",
		"& .MuiSelect-underline": {
			display: "none"
		},
		"& .MuiFilledInput-input": {
			paddingTop: 0,
			paddingBottom: 0
		}
	},
	selectWithSize: {
		backgroundColor: "#FFFFFF",
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		}
	},
	helperText: {
		margin: 0
	},
	label: {
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "12px",
		fontWeight: "500"
	},
	ellipsis: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis"
	}
}))

const SitesFilter = ({ sitesValue, setSitesValue, groupName }) => {
	// useUIContext
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			sitesValue: UIContext.sitesValue,
			groupId: UIContext.groupId,
			setSitesValue: UIContext.setSitesValue,
			setGroupName: UIContext.setGroupName,
			setSelectedValues: UIContext.setSelectedValues
		}
	}, [UIContext])

	// useSelector

	const { currentCustomer, allSites, allGroups, selectedSite } = useSelector(
		state => ({
			currentCustomer: state?.profile?.currentCustomer,
			allSites: state.manageSites.sites,
			allGroups: state?.manageGroups?.allGroups,
			selectedSite: state.profile?.currentSite
		}),
		shallowEqual
	)

	// useState

	//on main file to pass to confirm tab
	// const [value, setSitesValue] = useState([])

	const [touched, setTouched] = useState(false)
	const [options, setOptions] = useState([])

	// Const declaration

	const classes = useStyles()
	const dispatch = useDispatch()
	const ITEM_HEIGHT = 48
	const ITEM_PADDING_TOP = 8
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250
			}
		},
		anchorOrigin: {
			vertical: "bottom",
			horizontal: "left"
		},
		transformOrigin: {
			vertical: "top",
			horizontal: "left"
		},
		getContentAnchorEl: null
	}
	const name = "sites"
	const variant = "outlined"
	const customColor = "#F4F4F4"
	const selectAllEnabled = true

	const selectedNames = options
		.filter(option => sitesValue?.indexOf(option.value) !== -1)
		.map(option => option.name)

	const isSelectAll = sitesValue && sitesValue.length === options && options.length

	// handlers

	// Function to handle "Select All" toggle
	const handleSelectAllToggle = () => {
		if (isSelectAll) {
			// Deselect all options
			setSitesValue([])
		} else {
			// Select all options
			setSitesValue(options.map(option => option.value))
		}
		setTouched(true)
	}

	// useEffect
	useEffect(() => {
		if (!groupName) return
		UIProps.setGroupName(groupName)
	}, [])

	useEffect(() => {
		if (!sitesValue) return

		UIProps.setSitesValue(sitesValue)
	}, [sitesValue])

	// useEffect(() => {
	// 	if (!currentCustomer.id) return
	// 	dispatch(
	// 		actions.fetchSites({
	// 			customerId: currentCustomer.id
	// 		})
	// 	)
	// }, [currentCustomer.id])

	useEffect(() => {
		if (!currentCustomer || !allSites) return

		setOptions([
			{
				name: "No Site",
				value: false
			},
			...currentCustomer.sites.map(site => {
				return {
					name: site.name,
					value: site.id
				}
			})
		])
	}, [currentCustomer, allSites])

	useEffect(() => {
		if (!options) return

		if (UIProps.sitesValue && UIProps.sitesValue.length > 0) return

		let match = []

		if (selectedSite) {
			match = options
				.filter(option => option.value === selectedSite.id)
				.map(option => option.value)
		}

		setSitesValue && setSitesValue(selectedSite ? match : options.map(option => option.value))
	}, [options, selectedSite])

	return (
		<div data-testid="sites-filter">
			{/* dropdown for sites */}
			<FormControl className={classes.formControl} variant={variant}>
				<SelectMUI
					name={name}
					id="select-partial"
					disabled={!options.length > 0}
					className={classes.select}
					style={{
						backgroundColor: customColor ? customColor : ""
					}}
					value={sitesValue || []}
					multiple // Enable multiple selection
					MenuProps={MenuProps}
					onClose={() => {
						if (!touched) {
							setTouched(true)
						}
					}}
					onChange={e => {
						const selectedValues = e.target.value
						UIProps.setSelectedValues([])

						if (selectedValues.includes("all")) {
							handleSelectAllToggle()
						} else {
							setSitesValue(selectedValues)
						}
						if (!touched) {
							setTouched(true)
						}
					}}
					renderValue={() => selectedNames.join(", ")}
				>
					{selectAllEnabled && (
						<MenuItem key="select-all" onClick={handleSelectAllToggle} value="all">
							<Checkbox
								checked={isSelectAll}
								indeterminate={
									sitesValue?.length > 0 && sitesValue?.length < options?.length
								}
								color="primary"
							/>
							<ListItemText primary="Select All" />
						</MenuItem>
					)}

					{options.map((val, i) => (
						<MenuItem key={i} value={val.value} disabled={isSelectAll}>
							<Checkbox checked={sitesValue?.indexOf(val.value) > -1} color="primary" />
							<ListItemText primary={val.name} />
						</MenuItem>
					))}
				</SelectMUI>
			</FormControl>

			{/* counter for sites selected */}
			<div style={{ marginTop: "10px", marginBottom: "10px" }} className="row">
				{sitesValue && sitesValue.length > 0 && (
					<div className="col-8">
						<div className="row" style={{ paddingLeft: "12.5px" }}>
							<div
								className="col-4 d-flex"
								style={{ position: "relative", overflow: "auto", height: "31px" }}
							>
								{sitesValue &&
									sitesValue.slice(0, 6).map((data, index) => {
										const site = allSites?.find(aSite => aSite.id === data)
										return (
											<div
												key={index}
												className="icon-preview"
												style={{
													position: "absolute",
													left: `${index * 10}px`
												}}
											>
												{site?.siteInfo?.imageUrls?.[0] ? (
													<img
														src={site.siteInfo.imageUrls[0]}
														alt="people profile image placeholder"
													/>
												) : (
													<SVG src={"/media/crowdkeep/person.svg"} />
												)}
											</div>
										)
									})}
							</div>
							<div className="col-1 select-counter" style={{ fontWeight: 700 }}>
								{sitesValue?.length}
							</div>
							<div className="col select-counter">Sites Selected</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default SitesFilter
