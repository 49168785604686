import React, { useMemo, useState, useEffect } from "react"
import CloseIcon from "@material-ui/icons/Close"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../../_redux/admin/inventory/InventoryActions"
import { Button } from "../../../../_partials/Button"
import { useUIContext } from "../UIContext"
import { TextField, IconButton, makeStyles, ListItemIcon } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import { startButtonProgress, finishButtonProgress } from "../../utils/utils"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"
import ViewListIcon from "@material-ui/icons/ViewList"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import "../Inventory.css"
import { Modal } from "react-bootstrap"
import Alert from "@material-ui/lab/Alert"

const useStyles = makeStyles(() => ({
	autocomplete: {
		width: "100%"
	},
	select: {
		borderColor: "red",
		height: "36px",
		backgroundColor: "#F2F2F2",
		borderRadius: "5px",

		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "transparent"
		},
		"& .MuiInputBase-input": {
			fontWeight: "500"
		}
	}
}))

const AssignInventoryModal = ({ show, onHide }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	// ━━━━━━━━━━━━━  useState  ━━━━━━━━━━━━━ \\
	const [selectedCustomer, setSelectedCustomer] = useState(null)
	const [sitesByCustomerList, setSitesByCustomerList] = useState([])
	const [selectedSite, setSelectedSite] = useState(null)
	const [loadingSites, setLoadingSites] = useState(true)
	const [open, setOpen] = useState(false)
	const [saving, setSaving] = useState(false)

	// ━━━━━━━━━━━━━  useUIContext  ━━━━━━━━━━━━━ \\
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			ids: UIContext.idsInventoryNodes,
			setIds: UIContext.setIdsInventoryNodes,
			technology: UIContext.technology,
			rowsToUse: UIContext.rowsToUseNodesTable
		}
	}, [UIContext])
	// ━━━━━━━━━━━━━  Selectors  ━━━━━━━━━━━━━ \\
	const { customers, user, listLoading } = useSelector(
		state => ({
			listLoading: state.inventory.listLoading,
			customers: state?.inventory?.customers,
			user: state?.auth?.user
		}),

		shallowEqual
	)

	const [foundAssignedNode, setFoundAssignedNode] = useState(false)
	const [matchingNodesData, setMatchingNodesData] = useState([])

	useEffect(() => {
		if (!selectedCustomer) return
		setSelectedSite(null)

		if (selectedCustomer.sites && selectedCustomer.sites.length > 0) setLoadingSites(false)
		setSitesByCustomerList(selectedCustomer.sites)
	}, [selectedCustomer])

	useEffect(() => {
		if (
			!UIProps.rowsToUse ||
			UIProps.rowsToUse.length <= 0 ||
			!UIProps.ids ||
			UIProps.ids.length <= 0
		)
			return

		const matchedData = UIProps.rowsToUse
			.filter(node => UIProps.ids.includes(node.id))
			.map(node => {
				const customer = node.customerId
					? customers && customers.find(customer => customer.id === node.customerId)
					: null

				const site = customer?.sites?.find(site => site.id === node.siteId) || "none"

				return {
					...node,
					customerName: customer?.name || "none",
					siteName: site !== "none" ? site.name : "none"
				}
			})

		setMatchingNodesData(matchedData)

		const existsAssigned = matchedData.filter(
			node => node.status !== "unassigned" && node.status !== "created"
		)

		if (existsAssigned.length > 0) setFoundAssignedNode(existsAssigned.length)
		else setFoundAssignedNode(false)
	}, [UIProps.ids, UIProps.rowsToUse, customers])

	//  ━━━━━━━ Handlers ━━━━━━━
	const getSecondaryText = node => {
		return `Assigned to: ${node.customerName} (${node.siteName})`
	}

	const handleClick = () => {
		setOpen(!open)
	}
	const handleCustomerSelection = (event, newValue) => {
		setSelectedCustomer(newValue)
	}
	const handleSiteSelection = (event, newValue) => {
		setSelectedSite(newValue)
	}
	const handleSaveAssign = () => {
		if (
			!UIProps.ids ||
			UIProps.ids.length <= 0 ||
			!selectedCustomer ||
			!selectedSite ||
			!user ||
			!UIProps.technology
		)
			return

		setSaving(true)
		// Starts the change of the saving UI button like progress bar
		const buttonToProgressEl = document.getElementById("buttonToProgress_editPoi")
		buttonToProgressEl &&
			startButtonProgress(buttonToProgressEl, "button__progress_editPoi", "button__text_editPoi")

		dispatch(
			actions.assignInventoryNodes({
				technology: UIProps.technology,
				data: {
					customerId: selectedCustomer.id,
					databaseId: null,
					siteId: selectedSite.id,
					nodes: UIProps.ids,
					callersUserId: user.id || user.tenantId
				},
				onHide
			})
		)
			.then(() => {
				setSaving(false)

				// Change save/edit button
				const btnUploadFile = document.getElementById("buttonToProgress_editPoi")
				btnUploadFile &&
					finishButtonProgress(
						btnUploadFile,
						"button__progress_editPoi",
						"button__text_editPoi",
						100
					)

				onHide()
			})
			.catch(error => {
				console.error("Error saving:", error) // Handle error
				setSaving(false) // Reset saving state on error
			})
	}

	return (
		<>
			<Modal show={show} onHide={onHide} data-testid="assignInventoryModal" backdrop="static">
				<Modal.Header style={{ padding: "0.5rem 1rem 0 2rem", border: "none" }}>
					{/* ━━★  TITLE  ★━━ */}
					<div className="d-flex w-100 align-items-center justify-content-between">
						<div className="col-auto">
							<div className="row align-items-center">
								<SVG src={toAbsoluteUrl("/media/svg/icons/General/edit-node.svg")} />

								<div style={{ marginLeft: "10px" }}>
									<span className="custom-modal-title">Assign Nodes</span>
								</div>
							</div>
						</div>
						<div className="col-auto">
							<IconButton
								onClick={onHide}
								style={{ left: "12px" }}
								data-testid="close-button"
								disabled={saving}
							>
								<CloseIcon />
							</IconButton>
						</div>
					</div>
				</Modal.Header>
				<Modal.Body
					style={{
						padding: "1rem",
						overflow: "hidden"
					}}
				>
					{!listLoading && UIProps.ids && UIProps.ids.length >= 0 && foundAssignedNode && (
						<div className="mb-4">
							<Alert
								severity="warning"
								style={{
									color: "rgb(102, 60, 0)",
									backgroundColor: "rgb(255, 244, 229)",
									alignItems: "center"
								}}
							>
								Attention:{" "}
								<b>
									{foundAssignedNode}/{UIProps.ids.length}
								</b>{" "}
								of the selected nodes <b>are already assigned.</b> <br />
								These ones <b>will be re-assigned</b> to the selected site.
							</Alert>
						</div>
					)}

					<div className="mb-4">
						{/* com collapse com span com o nome e o uuid */}
						<List
							aria-labelledby="nested-list-subheader"
							// className={classes.root}
						>
							<ListItem button onClick={handleClick}>
								<ListItemIcon>
									<ViewListIcon />
								</ListItemIcon>
								<ListItemText primary="List of nodes to assign" />
								{open ? <ExpandLess /> : <ExpandMore />}
							</ListItem>
							<Collapse
								in={open}
								timeout="auto"
								unmountOnExit
								style={{
									maxHeight: "190px",
									overflowX: "scroll"
								}}
							>
								<List component="div" disablePadding>
									{matchingNodesData &&
										matchingNodesData.length > 0 &&
										matchingNodesData.map(node => {
											if (
												UIProps.ids &&
												UIProps.ids.length > 0 &&
												UIProps.ids.includes(node?.id)
											) {
												return (
													<ListItem
														key={node?.id}
														button
														className={classes.nested}
														style={{
															background:
																node.status !== "unassigned" &&
																node.status !== "created"
																	? "#FBE6DE"
																	: "transparent"
														}}
													>
														<ListItemIcon>
															<SVG
																style={{
																	fill: "#1C1C3B"
																}}
																src={toAbsoluteUrl(
																	"/media/svg/icons/General/cube.svg"
																)}
															/>
														</ListItemIcon>
														<ListItemText
															primary={node?.id}
															secondary={
																node.status !== "unassigned" &&
																node.status !== "created" &&
																getSecondaryText(node)
															}
														/>
													</ListItem>
												)
											}
											return null // If the id doesn't match, return null
										})}
								</List>
							</Collapse>
						</List>
					</div>

					<div className="drawer_title_container d-flex w-100 pl-3 pt-3 align-items-center justify-content-between">
						Customer
					</div>
					<div className="drawer_title_container d-flex w-100 pb-3 pl-3 pr-3 pt-1 align-items-center justify-content-between">
						<Autocomplete
							id="audit_tool_users_autocomplete"
							data-testid="customer-autocomplete"
							selectOnFocus
							clearOnBlur
							disableClearable
							disabled={saving}
							handleHomeEndKeys
							className={classes.autocomplete}
							options={customers || []}
							size="small"
							getOptionLabel={option => {
								return option.name || option.id || ""
							}}
							value={selectedCustomer}
							onChange={handleCustomerSelection}
							renderInput={params => (
								<TextField
									className={classes.select}
									{...params}
									placeholder="Select customer..."
									variant="outlined"
								/>
							)}
							popupIcon={<ExpandMoreIcon style={{ color: "#8C8CA2" }} />} // Your custom arrow icon
						/>
					</div>
					<div className="drawer_title_container d-flex w-100 pl-3 pt-3 align-items-center justify-content-between">
						Site
					</div>
					<div className="drawer_title_container d-flex w-100 pb-3 pl-3 pr-3 pt-1 align-items-center justify-content-between">
						<Autocomplete
							id="audit_tool_users_autocomplete"
							selectOnFocus
							clearOnBlur
							disableClearable
							disabled={loadingSites || saving}
							handleHomeEndKeys
							className={classes.autocomplete}
							options={sitesByCustomerList || []}
							size="small"
							getOptionLabel={option => {
								return option.name || option.id || ""
							}}
							value={selectedSite}
							onChange={handleSiteSelection}
							renderInput={params => (
								<TextField
									className={classes.select}
									{...params}
									placeholder="Select site..."
									variant="outlined"
								/>
							)}
							popupIcon={<ExpandMoreIcon style={{ color: "#8C8CA2" }} />} // Your custom arrow icon
						/>
					</div>
					<div className="drawer_title_container d-flex w-100 p-3 align-items-center justify-content-end">
						<Button
							color="primary"
							variant="outlined"
							text="Cancel"
							onClick={() => {
								onHide()
							}}
							disabled={saving}
						/>

						<Button
							disabled={!selectedCustomer}
							variant="contained"
							style={{
								marginLeft: "12px",
								// fontFamily: "Poppins",
								fontWeight: "700",
								backgroundColor: "#3077d3",
								color: "#f4f4f4"
							}}
							// text="Save"
							onClick={() => {
								handleSaveAssign()
							}}
							className="ml-2"
							id="buttonToProgress_editPoi"
							data-progress-text="Saving..."
							data-complete-text="Save complete."
						>
							<div className="button__progress_editPoi"></div>
							<span className="button__text_editPoi">Save</span>
						</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default AssignInventoryModal
