import React, { useState, useEffect, useRef } from "react"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import { AttendanceListTable } from "./AttendanceListTable"
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import { SearchBarCustom1 } from "../../../../_partials/inputs/SearchBarCustom1"
import { DatePicker } from "../../../../_partials/inputs/DatePicker"
import { TextField, InputAdornment, makeStyles } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import { MdOutlineCalendarToday } from "react-icons/md"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import { Button } from "../../../../_partials/Button"
import moment from "moment"
import { Timestamp } from "firebase/firestore"
import { LiveMapWidget } from "../LiveMap/LiveMapWidget"
import { isEqual, cloneDeep } from "lodash"

//for data grid
import { filterBySearchString } from "../../../../helpersGlobal/DataGridHelpers"

const useStyles = makeStyles(() => ({
	attendanceListDatePicker: {
		"& .MuiInputBase-root": {
			height: "36px"
		},
		"& .MuiOutlinedInput-input": {
			padding: "6px 0 7px",
			color: "#4A4A68",
			fontSize: "12px",
			fontFamily: "Poppins",
			fontStyle: "normal",
			fontWeight: "400",
			lineHeight: "normal"
		},
		"& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
			borderColor: "transparent"
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "transparent"
		}
	}
}))

export function AttendanceListCard({
	attendanceListSelectedPerson,
	setAttendanceListSelectedPerson
}) {
	const classes = useStyles()

	// ━━━━━━━━━━━━━  Selectors  ━━━━━━━━━━━━━ \\
	const { selectedSite, tags, assets, companies, people, areas } = useSelector(
		state => ({
			selectedSite: state.profile?.currentSite,
			tags: state.liveData.tags,
			assets: state.basePage?.assets,
			companies: state.profile?.currentCustomer?.vendors,
			people: state.basePage?.people || [],
			areas: state.liveData.areas
		}),

		shallowEqual
	)

	// ━━━━━━━━━ States  ━━━━━━━━━
	const [rowsAttendanceList, setRowsAttendanceList] = useState([])
	const [loading, setLoading] = useState(false)
	const [selectedDate, setSelectedDate] = useState(new Date())
	const [searchText, setSearchText] = useState("") //store the search text
	const [showPeople, setShowPeople] = useState(true)
	const [showAssets, setShowAssets] = useState(false)
	const [tableSubtitle, setTableSubtitle] = useState("List of people on site daily")
	const [tableTitle, setTableTitle] = useState("Attendance List")
	const [filteredData, setFilteredData] = useState(rowsAttendanceList || [])
	const [onSite, setOnSite] = useState(0)
	const [offSite, setOffSite] = useState(0)

	//Variables
	const [selectedPerson, setSelectedPerson] = useState(null)
	useEffect(() => {
		if (selectedPerson) {
			const mapElement = document.getElementById("live_map")
			if (mapElement) {
				mapElement.scrollIntoView({ behavior: "smooth" })
			}
		}
	}, [selectedPerson])

	//update the search text state variable.
	const handleSearch = value => {
		setSearchText(value)
	}

	// Update the selected date state with the new date value
	const handleDateChange = date => {
		setSelectedDate(date)
	}

	useEffect(() => {
		if (!selectedSite) return
		setLoading(true)

		if (!tags) {
			setLoading(false)
			return
		} else {
			const newRowsPeople = []
			const newRowsAssets = []

			tags.forEach(tag => {
				const person = tag.person
				const asset = tag.asset

				// if (!person || !tag.lastSeen) return
				if (!tag.lastSeen) return

				// const currentDate = moment(Timestamp.now().toDate())
				// const lastSeenDate = moment(tag.lastSeen.toDate())

				// if (!currentDate.isSame(lastSeenDate, "day")) return

				// if (!person) return

				if (!person && !asset) return

				let { id, status, firstSeen, lastSeen } = tag
				let dateCheckIn = null,
					formattedDateCheckIn = null,
					admissionDate = null,
					dateCheckOut = null,
					formattedDateCheckOut = null
				let stillValid = false

				if (selectedSite?.options?.needsValidation == true) {
					//convert validation to readable date
					// add the limit validation days
					const milliseconds =
						person?.validation?.seconds * 1000 + person?.validation?.nanoseconds / 1000000

					// Create a new Date object using the milliseconds
					const date = new Date(milliseconds)

					// Add 365 days to the date
					date.setDate(date.getDate() + 365)

					// Get the current date
					const currentDate = new Date()

					if (date > currentDate) stillValid = true
				}

				if (firstSeen) {
					const milliseconds =
						firstSeen.seconds * 1000 + Math.floor(firstSeen.nanoseconds / 1000000)
					const date = new Date(milliseconds)
					admissionDate = date.toLocaleDateString("pt-PT")

					dateCheckIn = new Date(firstSeen * 1000)
					formattedDateCheckIn = moment(dateCheckIn).format("hh:mm A")
					dateCheckOut = new Date(lastSeen * 1000)
					formattedDateCheckOut = moment(dateCheckOut).format("hh:mm A")
				}

				let totalOfHours = "-"
				try {
					const duration = moment.duration(dateCheckOut - dateCheckIn)
					const hours = Math.floor(duration.asHours())
					const minutes = Math.floor(duration.asMinutes()) % 60

					if (hours > 0) {
						totalOfHours = `${hours}h`
					}

					if (hours > 0 && minutes > 0) {
						totalOfHours += ` and ${minutes} min`
					} else if (hours <= 0 && minutes > 0) {
						totalOfHours = `${minutes} min`
					}
				} catch (error) {
					console.log("error:", error)
				}

				if (person) {
					const foundCompany =
						companies && companies.find(val => val?.id === person?.companyId)

					newRowsPeople.push({
						id: id,
						UID: person?.UID,
						active: tag?.tagState === "active",
						tagState: tag?.tagState,
						picture: person?.pictureUrl || "/media/crowdkeep/person.svg",
						name: person?.personalData?.name || "-",
						email: person?.personalData?.email || null,
						phoneNumber: person?.personalData?.phoneNumber || null,
						status: status || null,
						role: person?.role || "-",
						company: foundCompany?.company || "-",
						// company: person?.company || "-",
						checkIn: formattedDateCheckIn || "-",
						checkOut: formattedDateCheckOut || "-",
						stillValid: stillValid,
						totalHours: totalOfHours,
						uuid: tag.uuid,
						admissionDate: admissionDate || "-"
					})

					// console.log("newRowsPeople", newRowsPeople)
				}

				if (asset && !person) {
					const foundCompany = companies && companies.find(val => val?.id === asset?.companyId)

					newRowsAssets.push({
						id: id,
						UID: asset?.UID || asset?.uuid,
						picture: asset?.pictureUrl || "/media/crowdkeep/cube.svg",
						name: asset?.assetDetails?.name || "-",
						// email: person?.personalData?.email || null,
						// phoneNumber: person?.personalData?.phoneNumber || null,
						status: status || null,
						role: asset?.assetDetails?.type || "-",
						company: foundCompany?.company || "-",
						checkIn: formattedDateCheckIn || "-",
						checkOut: formattedDateCheckOut || "-",
						stillValid: stillValid,
						totalHours: totalOfHours
						//! Uncoment this when Audit tool works with assets
						// uuid: tag.uuid
					})
				}
			})

			if (showPeople) {
				setTableTitle("Attendance List")
				setTableSubtitle("List of admitted patients")
				setRowsAttendanceList(newRowsPeople)
			} else {
				setTableTitle("Asset Usage List")
				setTableSubtitle("List of assets on site daily")
				setRowsAttendanceList(newRowsAssets)
			}

			// Give marginTop on paginationFactory div
			const htmlCollection = document.getElementsByClassName("react-bootstrap-table-pagination")

			if (htmlCollection) {
				Array.from(htmlCollection).forEach(element => {
					element.style.marginTop = "2rem"
				})
			}
			setLoading(false)
		}
		setLoading(false)
	}, [selectedSite, tags, assets, selectedDate, showPeople, showAssets, companies])

	//added for data grid
	useEffect(() => {
		if (!rowsAttendanceList) return

		if (!searchText && searchText.trim() == "") setFilteredData(filteredRows)
		// return

		//TODO maybe: didn't add any of the 3 time fields to search but they work i tested it
		const fieldsToSearch = ["name", "role", "company"] // Define the fields to search
		const filteredRows = filterBySearchString(rowsAttendanceList, searchText, fieldsToSearch)

		if (filteredRows && filteredRows.length > 0) {
			setFilteredData(filteredRows)
		} else {
			setFilteredData([])
		}
	}, [rowsAttendanceList, searchText])

	useEffect(() => {
		// Get the number of people/assets on site and off site
		if (!tags || tags.length === 0 || !people || people.length === 0) return
		if (showPeople) {
			// People
			const activeTags = tags && tags.filter(tag => tag.person && tag.tagState === "active")
			const activePeople = activeTags && activeTags.length

			if (activePeople) {
				setOnSite(activePeople)
			} else {
				setOnSite(0)
			}

			const peopleWithTagAssigned = people && people.filter(person => person.uuid)
			const inactivePeople =
				activeTags &&
				peopleWithTagAssigned &&
				peopleWithTagAssigned
					.filter(person => !activeTags.some(tag => tag.uuid === person.uuid))
					.filter(val => !val.disabled).length

			setOffSite(inactivePeople)
		} else {
			// Assets
			const activeAssets =
				tags && tags.filter(tag => tag.asset && tag.tagState === "active").length

			if (activeAssets) {
				setOnSite(activeAssets)
			} else {
				setOnSite(0)
			}

			const inactiveAssets =
				tags && tags.filter(tag => tag.asset && tag.tagState === "inactive").length

			setOffSite(inactiveAssets)
		}
	}, [tags, people, showPeople, showAssets])

	return (
		<Card>
			<CardHeader
				title={tableTitle}
				// subtitle="List of people on site daily"
				subtitle={tableSubtitle}
				line={false}
				toolbar={
					<>
						{/* <DatePicker
							id="datePickerDataFilter"
							className={classes.attendanceListDatePicker}
							variant="dialog"
							fullWidth
							disableFuture
							disabled
							format="MM/dd/yyyy"
							value={selectedDate}
							onChange={handleDateChange}
							onYearChange={() => null}
							TextFieldComponent={params => {
								var value = params.value
								return (
									<TextField
										{...params}
										value={value}
										style={{
											width: "200px",
											height: "36px",
											backgroundColor: "#F2F2F2",
											marginRight: "10px",
											borderRadius: "5px"
										}}
										InputProps={{
											readOnly: true,
											startAdornment: (
												<InputAdornment position="start">
													<MdOutlineCalendarToday
														style={{
															color: "#7F8C8D",
															fontSize: "1.25rem",
															marginRight: "4px"
														}}
													/>
												</InputAdornment>
											)
										}}
									/>
								)
							}}
						/> */}
						<SearchBarCustom1
							onSearch={handleSearch}
							searchText={searchText}
							placeholder="Search"
							size="small"
							style={{
								width: "200px",
								background: "#F2F2F2",
								marginRight: "10px"
							}}
							InputProps={{
								startAdornment: (
									<SearchIcon
										fontSize="small"
										style={{
											color: "#7F8C8D",
											marginRight: "0.7rem"
										}}
									/>
								)
							}}
						/>
						{/* buttons to change between people and assets */}
						{/* <div>
							<Button
								id="attendance_list_show_people_btn"
								color={showPeople ? "primary" : "default"}
								variant="contained"
								style={{
									minWidth: "auto",
									width: "35px",
									height: "36.44px",
									backgroundColor: !showPeople && "transparent",
									border: showPeople ? "2px solid transparent" : "2px solid #D6D9E1"
								}}
								startIcon={
									<SVG
										style={{
											fill: showPeople ? "#FFFFFF" : "#D6D9E1"
										}}
										src={toAbsoluteUrl("/media/svg/icons/General/attendance-user.svg")}
									/>
								}
								onClick={() => {
									setShowPeople(prev => !prev)
									setShowAssets(prev => !prev)
								}}
							/>
							<Button
								id="attendance_list_show_assets_btn"
								color={showAssets ? "primary" : "default"}
								variant="contained"
								style={{
									minWidth: "auto",
									width: "35px",
									height: "36.44px",
									backgroundColor: !showAssets && "transparent",
									border: showAssets ? "2px solid transparent" : "2px solid #D6D9E1"
								}}
								startIcon={
									<SVG
										style={{
											fill: showAssets ? "#FFFFFF" : "#D6D9E1"
										}}
										src={toAbsoluteUrl("/media/svg/icons/General/cube.svg")}
									/>
								}
								onClick={() => {
									setShowAssets(prev => !prev)
									setShowPeople(prev => !prev)
								}}
							/>
						</div> */}
					</>
				}
			/>
			<CardBody
				id="dashboard_AttendanceListTable_card"
				style={{ height: "auto", overflow: "hidden", padding: "0px 40px" }}
			>
				{/* <div className="d-flex flex-lg-row flex-md-row flex-sm-row flex-column mb-2 mt-2">
					<div className="w-100 mr-0 mb-3 mr-lg-3 mr-md-3 mr-sm-3 mb-lg-0 mb-md-0 mb-sm-0">
						<GlobalPeopleInfo
							value={onSite}
							text={showPeople ? "On-site people" : "On-site assets"}
							icon="/media/crowdkeep/Person_present.svg"
							color="#DDE9FD"
						/>
					</div>
					<div className="w-100">
						<GlobalPeopleInfo
							value={offSite}
							text={showPeople ? "Off-site people" : "Off-site assets"}
							icon="/media/crowdkeep/Person_absent.svg"
							color="#DDE9FD"
						/>
					</div>
				</div> */}

				<AttendanceListTable
					loading={loading}
					selectedDate={selectedDate}
					needsValidation={selectedSite?.options?.needsValidation}
					filteredData={filteredData}
					setFilteredData={setFilteredData}
					showPeople={showPeople}
					areas={areas}
					attendanceListSelectedPerson={attendanceListSelectedPerson}
					setAttendanceListSelectedPerson={setAttendanceListSelectedPerson}
					selectedPerson={selectedPerson}
				/>
			</CardBody>
		</Card>
	)
}

export function GlobalPeopleInfo({ value, text, icon, color }) {
	return (
		<div
			id="dashboard_GlobalPeopleInfo_div_attendance"
			className="d-flex justify-content-between align-items-center"
			style={{
				backgroundColor: color,
				borderRadius: "5px",
				padding: "15px",
				height: "40px"
				// border: "1px solid green"
			}}
		>
			<div
				className="d-flex align-items-center justify-content-around"
				style={{
					fontFamily: "Poppins",
					fontStyle: "normal",
					// letterSpacing: "-0.02em",
					color: "#4A4A68"
					// border: "1px solid blue"
				}}
			>
				<div>
					<span
						style={{
							fontSize: "18px",
							lineHeight: "40px",
							fontWeight: "700"
						}}
					>
						{value && value}
					</span>
					<span
						className="top_row_info_text"
						style={{
							marginLeft: "10px",
							// marginTop: "-8px",
							fontSize: "11px",
							lineHeight: "18px",
							fontWeight: "500"
						}}
					>
						{text}
					</span>
				</div>
			</div>
			<div>
				<img alt="Person Info Icon" src={icon} style={{ width: "14px" }} />
			</div>
		</div>
	)
}
