import React, { useState, useEffect } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { useParams, Redirect } from "react-router-dom"
import { PersonDetailedPageForm } from "./PersonDetailedPageForm"

const PersonDetailedPage = () => {
	const { id } = useParams() // Access the id parameter from the URL

	const { selectedCustomer, currentFloorPlan, selectedSite, people, companies } = useSelector(
		state => ({
			selectedCustomer: state.profile?.currentCustomer,
			selectedSite: state.profile?.currentSite, // new selectedLocation
			currentFloorPlan: state.profile?.currentFloorPlan, // new selectedLevel
			people: state.basePage?.people,
			companies: state.profile?.currentCustomer?.vendors || []
		}),
		shallowEqual
	)

	var initialValues = {
		name: "",
		phoneNumber: "",
		email: "",
		role: "",
		geofencing: [],
		company: "",
		emergencyName: "",
		emergencyContact: "",
		startDate: null,
		helmetId: null,
		uuid: null,
		picture: null
	}

	const personToEdit = people && people.find(val => val.id === id)

	if (personToEdit) {
		const fullName = personToEdit.personalData?.name || ""

		// Split the name into an array of words
		const nameParts = fullName.trim().split(/\s+/) // Split by any whitespace, removes extra spaces

		// Check if there's at least one part (to avoid errors with empty or undefined input)
		const firstNameTrimmed = nameParts.length > 0 ? nameParts.slice(0, -1).join(" ") : "" // Take all but the last as first name
		const lastNameTrimmed = nameParts.length > 1 ? nameParts.slice(-1)[0] : "" // Last part is the last name

		initialValues = {
			// firstName: personToEdit.personalData?.name?.split(" ")[0] || "",
			// lastName: personToEdit.personalData?.name?.split(" ")[1] || "",
			firstName: firstNameTrimmed || "",
			lastName: lastNameTrimmed || "",
			phoneNumber: personToEdit.personalData?.phoneNumber || "",
			email: personToEdit.personalData?.email || "",
			nationality: personToEdit.personalData?.nationality,
			idCard: personToEdit.personalData?.idCard || "",
			helmetId: personToEdit.helmetId || "",
			passport: personToEdit.personalData?.passport || "",
			driverLicense: personToEdit.personalData?.driverLicense || "",
			birthdate: personToEdit.personalData?.birthdate
				? typeof personToEdit.personalData?.birthdate === "string"
					? new Date(personToEdit.personalData?.birthdate)
					: personToEdit.personalData?.birthdate?.toDate()
				: null,

			company: personToEdit.companyId
				? companies.find(val => val.id === personToEdit.companyId)?.company || null
				: null,
			role: personToEdit.role,
			uuid: personToEdit.uuid || null,
			emergencyName: personToEdit.emergencyName || "",
			emergencyNumber: personToEdit.emergencyNumber || "",
			supervisorName: personToEdit.supervisorName || "",
			supervisorNumber: personToEdit.supervisorNumber || "",
			startDate: personToEdit.startDate
				? typeof personToEdit.startDate === "string"
					? new Date(personToEdit.startDate)
					: personToEdit.startDate?.toDate()
				: null,
			geofencing:
				personToEdit.geofencing && personToEdit.geofencing.length > 0
					? personToEdit.geofencing
					: [],
			picture: personToEdit?.personalData?.picture ? personToEdit.personalData.picture : null
		}
	}

	// _______ useState _______ \\

	return (
		<div>
			{!personToEdit && <Redirect exact={true} to="/dashboard" />}

			<div>
				<PersonDetailedPageForm
					onHide={() => {
						return
					}}
					personToEdit={personToEdit}
					personId={id}
					initialValues={initialValues}
				/>
			</div>
		</div>
	)
}

export default PersonDetailedPage
