import { convertCollectionSnapshotToMap } from "../_helpers/ActionHelpers"
import * as requestFromServer from "./manageSitesCrud"
import { callTypes, manageSitesSlice } from "./manageSitesSlice"

const { actions } = manageSitesSlice

export const fetchSites = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return requestFromServer
		.getSites(queryParams)
		.then(response => {
			if (!response) throw Error("Can't make a request for server")

			const sites = convertCollectionSnapshotToMap(response)

			dispatch(actions.sitesFetched(sites))
		})
		.catch(error => {
			console.log("Fetch sites: ", error)
			error.clientMessage = "Can't fetch sites"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

//this was made to reduze the reads made to firestore when assigning nodes.
//each time i need to call this i save the data returned so i dont need to make the same fetch twice.
export const fetchSitesForInventory = queryParams => dispatch => {
	return requestFromServer
		.getSites(queryParams)
		.then(response => {
			if (!response) throw Error("Can't make a request for server")

			const sites = convertCollectionSnapshotToMap(response)
			return sites
		})
		.catch(error => {
			console.log("Fetch sites: ", error)
			error.clientMessage = "Can't fetch sites"
		})
}

export const createSite = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.createSite(queryParams)
		.then(response => response.json())
		.then(result => {
			if (!result) throw Error("Can't make a request for server")
			if (!result.success) throw Error(result.message)

			dispatch(actions.siteCreated(result.message))
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: "Site created with success"
			})
			queryParams.onHide()
		})
		.catch(error => {
			console.log("Create site error: ", error)
			error.clientMessage = "Can't create site"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: "Error creating site, please try again."
			})
			queryParams.onHide()
		})
}

export const editSite = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.editSite(queryParams)
		.then(response => response.json())
		.then(result => {
			if (!result) throw Error("Can't make a request for server")
			if (!result.success) throw Error(result.message)

			const _site = {
				...queryParams.site,
				...result.message
			}

			dispatch(actions.siteEdited(_site))
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: "Site edited with success"
			})
			queryParams.onHide()
		})
		.catch(error => {
			console.log("Edit site error: ", error)
			error.clientMessage = "Can't edit site"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: "Error editting site, please try again."
			})
			queryParams.onHide()
		})
}

export const disableSite = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.disableSite(queryParams)
		.then(() => {
			const _site = {
				...queryParams.site,
				id: queryParams.siteID,
				disabled: queryParams.disabled
			}
			dispatch(actions.siteEdited(_site))
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Site ${queryParams.disabled ? "enabled" : "disabled"} with success`
			})
			queryParams.onHide()
		})
		.catch(error => {
			console.log("Disable site error: ", error)
			error.clientMessage = "Can't disable site"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: "Error please try again."
			})
			queryParams.onHide()
		})
}
