import React from "react"
import { createMuiTheme, ThemeProvider, CssBaseline } from "@material-ui/core"

const theme = createMuiTheme(
	/**
	 * @see https://material-ui.com/customization/themes/#theme-configuration-variables
	 */
	{
		// direction: "rtl",
		typography: {
			fontFamily: ["Poppins"].join(",")
		},
		palette: {
			primary: {
				// light: will be calculated from palette.primary.main,
				// light: "#80b7ff !important",
				//Changed it to be like figma sign out btn
				light: "#D9EAFD ",
				main: "#2673F0 ",
				dark: "#23579c "
				// dark: will be calculated from palette.primary.main,
				// contrastText: "#fff" //will be calculated to contrast with palette.primary.main
			},
			secondary: {
				// light: will be calculated from palette.primary.main,
				light: "#E7E5FF !important",
				main: "#635AFF !important"
				// dark: will be calculated from palette.primary.main,
				// contrastText: "#fff" //will be calculated to contrast with palette.primary.main
			},
			success: {
				light: "#B5DDB3 !important",
				main: "#E6F4E6",
				dark: "#244C22 !important"
				// contrastText: "#fff" //will be calculated to contrast with palette.primary.main
			},
			warning: {
				light: "#FDF1DC ",
				main: "#F6B952 !important"
				// dark: will be calculated from palette.primary.main,
				// contrastText: "#fff" //will be calculated to contrast with palette.primary.main
			},
			error: {
				light: "#FBE6DE ",
				main: "#E65019 "
				// dark: will be calculated from palette.primary.main,
				// contrastText: "#fff" //will be calculated to contrast with palette.primary.main
			},
			grey: {
				light: "#E9E9E9 !important",
				main: "#BBBBBB !important",
				dark: "#494949 !important"
				// contrastText: "#fff" //will be calculated to contrast with palette.primary.main
			},
			action: {
				disabledBackground: "rgba(0, 0, 0, 0.12) !important"
				// disabled: 'set color of text here'
			}
		},

		/**
		 * @see https://material-ui.com/customization/globals/#default-props
		 */
		props: {
			// Name of the component ⚛️
			MuiButtonBase: {
				// The properties to apply
				disableRipple: true // No more ripple, on the whole application 💣!
			},

			// Set default elevation to 1 for popovers.
			MuiPopover: {
				elevation: 1
			}
		}
	}
)

export function MaterialThemeProvider(props) {
	const { children } = props

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			{children}
		</ThemeProvider>
	)
}
