import React, { useMemo, useState, useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import SVG from "react-inlinesvg"

/* import { DataGrid, GridPagination,useGridSlotComponentProps } from "@material-ui/data-grid" */
import { DataGrid } from "@mui/x-data-grid"
import { useGridApiContext, useGridRootProps } from "@mui/x-data-grid"
import Box from "@mui/material/Box"

import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap"
import { makeStyles } from "@material-ui/core"

import * as UIHelpers from "../../UIHelpers"
import { useUIContext } from "../../UIContext"
import { Button } from "../../../../_partials/Button"
import { ModalProgressBar } from "../../../../_partials/ModalProgressBar"
import { sortCaret } from "../../../../../_metronic/_helpers"
import { SendMessageModal } from "../LiveMap/SendMessageModal/SendMessageModal"

import "./AttendanceList.css"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Pagination } from "@material-ui/lab"
import LiveMapWidget from "../LiveMap/LiveMapWidget"
import {
	totalHoursComparator,
	timeStringComparator
} from "../../../../helpersGlobal/DataGridHelpers"

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		border: "none",
		"& .MuiPagination-root makeStyles-root-130": {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},

		"& .MuiDataGrid-cell:focus": {
			outline: "none"
		},
		"& .MuiDataGrid-selectedRowCount": {
			display: "none"
		},
		"& .MuiDataGrid-colCell": {
			minWidth: "0" // Ensure that minWidth is set to 0 to allow percentage width
		},
		"& .MuiDataGrid-columnSeparator": {
			opacity: 0
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
			fontWeight: 600,
			fontSize: "1rem",
			color: "#4a4a68"
		},
		"& .MuiDataGrid-cell.row-cell .MuiDataGrid-cell--textLeft": {
			fontSize: "14px",
			fontWeight: 500
		},
		"& .MuiDataGrid-columnHeaderTitleContainer": {
			paddingLeft: 0,
			paddingRight: 0
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
			paddingRight: "0px",
			paddingLeft: "10px"
		},
		"& .hide-scrollbar .MuiDataGrid-scrollbar": {
			display: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
			paddingLeft: "2px"
		},

		"& .MuiDataGrid-cell.row-cell .MuiDataGrid-cell--withRenderer .MuiDataGrid-cell--textLeft": {
			maxHeight: "65px !important",
			minHeight: "65px !important"
		},
		"& .MuiPagination-root": {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},
		"& .MuiPaginationItem-root": {
			borderRadius: "3px !important",
			color: "#3077d3 !important",
			padding: "0 !important",
			margin: "-1px !important",
			border: "1px solid #f4f8fe !important"
		},

		"& .MuiPaginationItem-textPrimary.Mui-selected": {
			color: "#fff !important",
			border: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon, & .MuiDataGrid-root .MuiDataGrid-filterIcon, & .MuiDataGrid-root .MuiDataGrid-sortIcon":
			{
				color: "#3077d3"
			},
		"& .MuiDataGrid-root .MuiDataGrid-viewport": {
			minHeight: "700px",
			maxHeight: "none"
		},
		"& .MuiDataGrid-renderingZone": {
			maxHeight: "none"
		},
		"& .MuiDataGrid-root .MuiDataGrid-footerContainer": {
			minHeight: "90px !important"
		}
	},

	"& .MuiDataGrid-columnSeparator": {
		opacity: "0 !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle": {
		fontWeight: "600 !important",
		fontSize: "1rem",
		color: "#4a4a68"
	},

	"& .MuiDataGrid-cell row-cell .MuiDataGrid-cell--textLeft": {
		fontSize: "14px !important",
		fontWeight: "500 !important"
	},

	"& .MuiDataGrid-columnHeaderTitleContainer": {
		paddingLeft: "0 !important",
		paddingRight: "0 !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
		paddingRight: "0px !important",
		paddingLeft: "10px !important"
	},

	"& .MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
		paddingLeft: "2px"
	},

	"& .MuiDataGrid-root .MuiDataGrid-row:hover": {
		backgroundColor: "inherit !important"
	},

	"& .MuiDataGrid-colCell": {
		minWidth: 0
	},

	"& .actions-header": {
		marginLeft: "10px"
	},

	"& .MuiDataGrid-row": {
		maxHeight: "65px !important",
		minHeight: "65px !important"
	},

	"& .MuiDataGrid-withBorder": {
		borderRight: "none !important"
	},

	"& .MuiDataGrid-cell row-cell .MuiDataGrid-cell--withRenderer .MuiDataGrid-cell--textLeft": {
		maxHeight: "65px !important",
		minHeight: "65px !important"
	},

	"& .MuiPagination-root": {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},

	"& .MuiPaginationItem-root": {
		borderRadius: "3px !important",
		color: "#3077d3 !important",
		padding: "0 !important",
		margin: "0 !important",
		border: "1px solid #f4f8fe !important"
	},

	"& .MuiPaginationItem-textPrimary.Mui-selected": {
		color: "#fff !important",
		border: "none"
	},

	"& .MuiDataGrid-root .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon":
		{
			color: "#3077d3"
		},

	"& .MuiDataGrid-root .MuiDataGrid-sortIcon, .MuiDataGrid-root .MuiDataGrid-filterIcon": {
		color: "#3077d3"
	},

	"& .MuiDataGrid-root .MuiDataGrid-viewport": {
		minHeight: "700px",
		maxHeight: "none"
	},

	"& .MuiDataGrid-renderingZone": {
		maxHeight: "none"
	},

	"& .MuiDataGrid-root .MuiDataGrid-footerContainer": {
		minHeight: "90px !important"
	}
}))

// function CustomPagination() {
// 	const { state, apiRef } = useGridSlotComponentProps()
// 	const classes = useStyles()

// 	return (
// 		<Pagination
// 			className={classes.root}
// 			color="primary"
// 			count={state.pagination.pageCount}
// 			page={state.pagination.page + 1}
// 			onChange={(event, value) => apiRef.current.setPage(value - 1)}
// 		/>
// 	)
// }

function CustomPagination() {
	const apiRef = useGridApiContext()
	const state = apiRef.current.state
	const rootElement = apiRef.current.rootElementRef
	const rootProps = useGridRootProps()
	const classes = useStyles()
	const options = [5, 10, 20, 30, 50, 100]

	const handlePageSizeChange = pageSize => {
		apiRef.current.setPageSize(pageSize)
	}

	return (
		<>
			<Dropdown>
				<Dropdown.Toggle variant="light" id="dropdown-basic">
					{state.pagination.pageSize}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{options.map(option => {
						return (
							<Dropdown.Item
								key={option}
								as="button"
								onClick={() => handlePageSizeChange(option)}
							>
								{option}
							</Dropdown.Item>
						)
					})}
				</Dropdown.Menu>
			</Dropdown>

			<Pagination
				className={classes.root}
				id="custom-pagination"
				color="primary"
				count={state.pagination.pageCount}
				page={state.pagination.page + 1}
				onChange={(event, value) => apiRef.current.setPage(value - 1)}
			/>
		</>
	)
}

export function AttendanceListTable({
	loading,
	selectedDate,
	needsValidation,
	filteredData,
	setFilteredData,
	showPeople,
	areas,
	onLocate,
	attendanceListSelectedPerson,
	setAttendanceListSelectedPerson
	//*TODO FUTURE use on filter by date API
	// selectedDate
}) {
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			ids: UIContext.idsNodes,
			setIds: UIContext.setIdsNodes
		}
	}, [UIContext])

	const { isSuper } = useSelector(
		state => ({
			isSuper: state.auth?.claims?.isSuper
		}),
		shallowEqual
	)

	const classes = useStyles()
	const liveMapRef = useRef(null)

	const handleLocateClick = row => {
		const element = document.getElementById("dashboard_live_map_widget_card")
		if (element) {
			element.scrollIntoView({ behavior: "smooth" })
		}
		row && setAttendanceListSelectedPerson(row)
	}
	const dispatch = useDispatch()

	const rows = [
		{
			id: 1,
			name: "Loading...",
			role: "Loading...",
			company: "Loading...",
			checkIn: "Loading...",
			checkOut: "Loading...",
			totalHours: "Loading...",
			actions: "Loading..."
		},
		{
			id: 2,
			name: "Loading...",
			role: "Loading...",
			company: "Loading...",
			checkIn: "Loading...",
			checkOut: "Loading...",
			totalHours: "Loading...",
			actions: "Loading..."
		},
		{
			id: 3,
			name: "Loading...",
			role: "Loading...",
			company: "Loading...",
			checkIn: "Loading...",
			checkOut: "Loading...",
			totalHours: "Loading...",
			actions: "Loading..."
		},
		{
			id: 4,
			name: "Loading...",
			role: "Loading...",
			company: "Loading...",
			checkIn: "Loading...",
			checkOut: "Loading...",
			totalHours: "Loading...",
			actions: "Loading..."
		}
	]

	const [sendMessageModal, setSendMessageModal] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const sendMessageModalOnHide = () => setSendMessageModal()
	const [dataRows, setDataRows] = useState(rows)
	const [showScrollBar, setShowScrollBar] = useState()

	//for data grid
	const [page, setPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [rowCounter, setRowCounter] = useState(0)
	const [rowCountState, setRowCountState] = useState(rowCounter)
	const [currentRows, setCurrentRows] = useState([])
	const [sortModel, setSortModel] = useState([
		{
			field: "name",
			sort: "asc"
		}
	])

	let columns = [
		{
			field: "name",
			headerName: showPeople ? "People" : "Assets",
			flex: 1,
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			editable: false,
			minWidth: 190,
			renderCell: params => {
				const { value, row } = params
				// if (row.id === "132149880889893") {
				// 	console.log("🚀  row:", row)
				// }
				const tagState = row.tagState
				const picture = row.picture
				// console.log("🚀  picture:", picture)
				return (
					<div
						style={{
							display: "flex",
							alignItems: "center"
						}}
					>
						<div
							style={{
								marginRight: "9px",
								width: "42px",
								height: "42px",
								borderRadius: "42px",
								background: "#E6EBEF",
								display: "grid",
								justifyItems: "center",
								alignItems: "center"
							}}
						>
							<div
								style={{
									position: "sticky",
									marginLeft: "29px",
									marginBottom: "-13px",
									zIndex: "1",
									width: "12px",
									height: "12px",
									borderRadius: "14px",
									border: "1.5px solid #FFF",
									background:
										tagState === "active"
											? "#53B04F"
											: tagState === "idle"
											? "#F6B952"
											: "#D1D1D1"
								}}
							></div>
							<img
								src={picture}
								alt="people profile image placeholder"
								style={
									picture === "/media/crowdkeep/person.svg"
										? { borderRadius: "42px", marginRight: "1px", marginBottom: "2px" }
										: {
												width: showPeople ? "inherit" : "20px",
												height: "inherit",
												borderRadius: "42px"
										  }
								}
							/>
						</div>
						<span
							style={{
								alignSelf: "center"
							}}
						>
							{value}
						</span>
					</div>
				)
			}
		},
		// {
		// 	field: "role",
		// 	headerName: showPeople ? "Role" : "Type",
		// 	flex: 0.4,
		// 	minWidth: 85,
		// 	headerClassName: "header-cell",
		// 	cellClassName: "row-cell",
		// 	editable: false
		// },
		// {
		// 	field: "company",
		// 	headerName: "Company",
		// 	minWidth: 100,
		// 	flex: 0.42,
		// 	headerClassName: "header-cell",
		// 	cellClassName: "row-cell",
		// 	editable: false
		// },
		{
			field: "admissionDate",
			headerName: "Admission Date",
			minWidth: 90,
			flex: 0.5,
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			editable: false
		},
		{
			field: "checkIn",
			headerName: "First Seen",
			minWidth: 90,
			flex: 0.4,
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			editable: false,
			sortComparator: timeStringComparator
		},
		{
			field: "checkOut",
			headerName: "Last Seen",
			minWidth: 90,
			flex: 0.4,
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			editable: false,
			sortComparator: timeStringComparator
		},
		{
			field: "totalHours",
			headerName: "Total Hours",
			minWidth: 120,
			flex: 0.5,
			headerClassName: "header-cell",
			cellClassName: "row-cell",
			editable: false,
			sortComparator: totalHoursComparator
		},
		{
			field: "actions",
			headerName: "Actions",
			flex: 0.5,
			headerClassName: "header-cell actions-header",
			cellClassName: "row-cell",
			renderCell: params => {
				const row = params.row
				return (
					<div
						data-testid={`actions-cell`}
						style={{ display: "flex", alignItems: "center", width: "100%" }}
					>
						{/* only show on people */}
						{row && showPeople && (
							<>
								<OverlayTrigger
									placement="bottom"
									overlay={<Tooltip id="firebase-messaging-tooltip">Send Text</Tooltip>}
								>
									<Button
										data-testid="message_button"
										variant="text"
										onClick={() => {
											// if (!row.UID && !row.phoneNumber) return
											setSendMessageModal({
												person: row
											})
										}}
										style={{ padding: "0.5rem", minWidth: "auto", marginRight: "10px" }}
									>
										<img alt="Directions" src="/icons/email.svg" />
									</Button>
								</OverlayTrigger>
							</>
						)}
						{/* only shows on users that have a valid UID because its needed for audit*/}
						{row && row.uuid && isSuper && (
							<>
								<OverlayTrigger
									placement="bottom"
									overlay={
										<Tooltip id="firebase-messaging-tooltip">Go to Audit Tool</Tooltip>
									}
								>
									<Button style={{ marginLeft: "3px" }} data-testid="audit_tool_button">
										<Link
											to={{
												pathname: "/audit-tool",
												person: row,
												areas: areas || []
											}}
											id="attendance_list_link_to_audit_btn"
										>
											<img alt="Audit" src="/icons/to-audit.svg" />
										</Link>
									</Button>
								</OverlayTrigger>
								{/* Locate Button */}
								{row && row.uuid && isSuper && (
									<OverlayTrigger
										placement="bottom"
										overlay={<Tooltip id="firebase-messaging-tooltip">Locate</Tooltip>}
									>
										<Button
											style={{ marginLeft: "3px", objectFit: "contain" }}
											onClick={() => handleLocateClick(row)}
										>
											<img
												alt="Locate"
												src="/icons/location--person.svg"
												width="22"
												height="22"
											/>
										</Button>
									</OverlayTrigger>
								)}
							</>
						)}
					</div>
				)
			},
			editable: false
		}
	]

	// _________ UseEffect _________
	//added for data grid
	// Update current rows when rows or current page changes
	useEffect(() => {
		if (!filteredData) return
		updateCurrentRows()
	}, [filteredData, page, pageSize])
	//added for data grid
	useEffect(() => {
		setRowCountState(prevRowCountState =>
			rowCounter !== undefined ? rowCounter : prevRowCountState
		)
	}, [rowCounter])
	//added for data grid
	// Function to update the current rows based on the current page and page size
	const updateCurrentRows = () => {
		const startIndex = page * pageSize
		const endIndex = Math.min(
			startIndex + pageSize,
			filteredData?.length ? filteredData?.length : 0
		)
		const newCurrentRows = filteredData?.slice(startIndex, endIndex)
		setCurrentRows(newCurrentRows)
		setRowCounter(filteredData.length)
		// UIProps.setRowsToUse(filteredData) //for printing all data
	}
	//added for data grid
	// Update the handleSortModelChange function in your PeopleTable component
	const handleSortModelChange = newSortModel => {
		// Sort the full dataset (rowsToUse) based on the sort model
		const sortedRows = [...filteredData].sort((a, b) => {
			// Iterate over the sort model
			for (const sortItem of newSortModel) {
				const field = sortItem.field
				const sortOrder = sortItem.sort === "asc" ? 1 : -1 // Only consider asc and desc
				if (sortItem.sort !== null) {
					if (field === "disabled") {
						// Sort based on boolean value for the "disabled" field
						if (a[field] && !b[field]) return sortOrder
						if (!a[field] && b[field]) return -sortOrder
						continue // Move to the next sort item
					}
					// For other fields, handle sorting based on their type
					if (a[field] === null && b[field] === null) continue // If both values are null, maintain current order
					if (a[field] === null) return -sortOrder // If 'a' is null, place it before 'b'
					if (b[field] === null) return sortOrder // If 'b' is null, place it before 'a'
					// Sort non-null values based on column type
					if (typeof a[field] === "string") {
						const comparison = a[field].localeCompare(b[field], undefined, { numeric: true })
						if (comparison !== 0) return comparison * sortOrder
					} else if (typeof a[field] === "number") {
						return (a[field] - b[field]) * sortOrder
					}
				}
			}
			return 0 // If no column has a defined sort order, maintain current order
		})

		// Update the sorted data and currentRows
		setFilteredData(sortedRows) //all data
		setCurrentRows(sortedRows.slice(page * pageSize, (page + 1) * pageSize))
		setSortModel(newSortModel)
	}

	useEffect(() => {
		if (!filteredData) {
			setDataRows(rows)
		} else {
			setDataRows(filteredData)
		}
	}, [filteredData])

	useEffect(() => {
		if (window.innerWidth > 700) {
			setShowScrollBar(true)
		} else {
			setShowScrollBar(false)
		}
	}, [])

	if (needsValidation) {
		// Find the index of "name" column
		const nameIndex = columns.findIndex(column => column.dataField === "name")
		// console.log("🚀 ~ nameIndex:", nameIndex)

		// Insert the new column right next to "name"
		columns.splice(nameIndex + 1, 0, {
			dataField: "stillValid",
			text: "Validation",
			sort: true,
			sortCaret: sortCaret,
			classes: "align-middle",
			formatter: (cell, row) => {
				//for status color controll
				const valid = row.stillValid

				return (
					<div style={{ marginLeft: "5px" }}>
						{valid ? (
							<SVG style={{ fill: "#323232" }} src="/media/svg/icons/General/Check.svg" />
						) : (
							<SVG style={{ fill: "#323232" }} src="/media/svg/icons/General/Cross.svg" />
						)}
					</div>
				)
			}
		})
	}

	const pageSizeOptions = UIHelpers.sizePerPageList.map(option => ({
		text: option,
		page: option
	}))

	const CustomPageSizeSelector = ({ options, currSizePerPage, onSizePerPageChange }) => (
		<Dropdown>
			<Dropdown.Toggle variant="light" id="dropdown-basic">
				{currSizePerPage}
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{options.map(option => (
					<Dropdown.Item
						key={option.text}
						as="button"
						onClick={() => onSizePerPageChange(option.page)}
					>
						{option.text}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	)

	const paginationOptions = {
		pageSizeOptions: [10, 20, 30], // Customize your page size options
		paginationComponent: CustomPageSizeSelector
	}

	return (
		<>
			<SendMessageModal
				show={sendMessageModal ? true : false}
				onHide={sendMessageModalOnHide}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				person={sendMessageModal?.person}
			/>
			<div
				className={`${classes.root} ${showScrollBar ? "" : "hide-scrollbar"}`}
				data-testid="attendance_list_toolkit_provider"
				style={{ height: "100%", width: "100%" }}
			>
				{loading && <ModalProgressBar variant="query" />}
				{dataRows && (
					<Box sx={{ width: "100%" }}>
						<DataGrid
							rows={dataRows || []}
							columns={columns}
							style={{
								fontFamily: "Poppins",
								fontWeight: 400,
								fontSize: "13px",
								border: "none",
								width: "100%",
								color: "#464e5f"
							}}
							className={classes.root}
							density="standard"
							// disableExtendRowFullWidth={true}
							pagination
							disableSelectionOnClick
							loading={isLoading}
							disableColumnMenu={true}
							components={{
								Pagination: CustomPagination
							}}
							rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
							page={page}
							onPageChange={newPage => {
								setPage(newPage)
							}}
							pageSize={pageSize}
							onPageSizeChange={e => {
								setPageSize(e)
							}}
							autoHeight
							rowCount={rowCountState}
							//* for printing with matching table order
							sortingOrder={["desc", "asc"]}
							sortModel={sortModel}
							onSortModelChange={handleSortModelChange}
						/>
					</Box>
				)}
			</div>
		</>
	)
}
