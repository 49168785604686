/* eslint-disable react/display-name */
import React, { useEffect, useState, forwardRef } from "react"
import { Button } from "../../../_partials/Button"
import { firestore as db } from "../../../../firebase"
import { collection, query, where, orderBy, onSnapshot, Timestamp } from "firebase/firestore"

import { useSelector, shallowEqual } from "react-redux"
import FirebaseMessagingModalBody from "./FirebaseMessagingModalBody"

import { Modal, Backdrop, Badge } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import MailOutlinedIcon from "@material-ui/icons/MailOutlined"

import { OverlayTrigger, Tooltip } from "react-bootstrap"

import notificationSound from "../../../assets/sounds/notificationSound.wav"

const useStyles = makeStyles(() => ({
	badge: {
		marginRight: "5px",
		"& .MuiBadge-anchorOriginTopRightRectangle.MuiBadge-invisible": {
			transform: "scale(0) translate(25%, 0%)"
		},
		"& .MuiBadge-anchorOriginTopRightRectangle": {
			transform: "scale(1) translate(25%, 0%)"
		}
	},
	shakeAnimation: {
		animation: "$shake 500ms",
		animationIterationCount: 3,
		animationTimingFunction: "cubic-bezier(0.36, 0.07, 0.19, 0.97)"
	},
	"@keyframes shake": {
		"0%": {
			transform: "translateX(0)"
		},
		"20%": {
			transform: "translateX(-5px) rotate(-10deg)"
		},
		"40%": {
			transform: "translateX(5px) rotate(10deg)"
		},
		"60%": {
			transform: "translateX(-5px) rotate(-10deg)"
		},
		"80%": {
			transform: "translateX(5px) rotate(10deg)"
		},
		"100%": {
			transform: "translateX(0) rotate(0)"
		}
	}
}))

const FirebaseMessaging = () => {
	const { selectedCustomer, messagingSwitch, user, messagingVolume } = useSelector(
		state => ({
			user: state.auth?.user,
			selectedCustomer: state.profile?.currentCustomer,
			messagingSwitch: state.notifications?.messagingSwitch,
			messagingVolume: state.notifications?.messagingVolume || 50
		}),
		shallowEqual
	)
	const [buttonColor, setbuttonColor] = useState()
	const [newMessagesCounter, setNewMessagesCounter] = useState(0)
	const [open, setOpen] = React.useState(false)
	const [allMessages, setAllMessages] = useState([])
	const [showAnimation, setShowAnimation] = useState(false)

	const handleOpenMessaging = () => {
		setbuttonColor("primaryLight")
		setOpen(true)
	}

	const handleCloseMessaging = () => {
		newMessagesCounter <= 0 && setbuttonColor()
		setOpen(false)
	}

	const playSound = () => {
		// the solution to overcome the autoplay restrictions
		// is to use the Web Audio API instead of the HTML5 Audio element.
		// The Web Audio API provides more control over audio playback and doesn't have the same autoplay restrictions.
		// Create an AudioContext instance
		const AudioContext = window.AudioContext || window.webkitAudioContext
		const audioContext = new AudioContext()

		// Fetch the audio file
		fetch(notificationSound)
			.then(response => response.arrayBuffer())
			.then(buffer => {
				// Decode the audio data
				return audioContext.decodeAudioData(buffer)
			})
			.then(decodedData => {
				// Create an AudioBufferSourceNode
				const source = audioContext.createBufferSource()
				source.buffer = decodedData

				// Connect the AudioBufferSourceNode to the AudioContext's destination
				// source.connect(audioContext.destination)

				// Set the volume based on the volumeValue state
				const gainNode = audioContext.createGain()
				// Convert messagingVolume to a value between 0 and 1
				gainNode.gain.value = messagingVolume >= 5 ? messagingVolume / 75 : 0
				source.connect(gainNode)
				gainNode.connect(audioContext.destination)

				// Start playing the audio
				source.start(0)
			})
			.catch(error => {
				console.log("Error loading audio:", error)
			})
	}

	// useEffect(() => {
	// 	if (!selectedCustomer || !selectedCustomer.id) return

	// 	if (messagingSwitch === true) {
	// 		if (!user) return

	// 		const firebaseServerDateTime = Timestamp.now().toDate()
	// 		const twentyFourHoursAgo = new Date(firebaseServerDateTime - 24 * 60 * 60 * 1000)

	// 		const messagesRef = collection(firestoreOld, `Customers/${selectedCustomer.id}/Messages`)

	// 		const q = query(
	// 			messagesRef,
	// 			where("type", "==", "message"),
	// 			where("erase", "==", false),
	// 			where("uidTo", "==", `${user.id}-messages`),
	// 			where("date", ">=", twentyFourHoursAgo),
	// 			orderBy("date", "desc")
	// 		)

	// 		const unsubscribe = onSnapshot(q, snapshot => {
	// 			const data = snapshot.docs.map(doc => ({
	// 				id: doc.id,
	// 				...doc.data()
	// 			}))

	// 			setAllMessages(data)

	// 			let counter = 0
	// 			data.forEach(message => {
	// 				if (!message.read) {
	// 					counter++
	// 					// playSound()
	// 					setShowAnimation(true)
	// 				}
	// 			})

	// 			if (counter > newMessagesCounter) {
	// 				playSound()
	// 			}

	// 			setNewMessagesCounter(counter)

	// 			if (counter > 0) {
	// 				setbuttonColor("primaryLight")
	// 			}
	// 		})

	// 		return () => {
	// 			unsubscribe()
	// 		}
	// 	} else {
	// 		return
	// 	}
	// }, [selectedCustomer, user, messagingSwitch])

	useEffect(() => {
		if (!selectedCustomer || !selectedCustomer.id) return

		if (messagingSwitch === true) {
			if (!user) return

			const firebaseServerDateTime = Timestamp.now().toDate()
			const twentyFourHoursAgo = new Date(firebaseServerDateTime - 24 * 60 * 60 * 1000)

			const messagesRef = collection(db, `Customers/${selectedCustomer.id}/Messages`)

			const q = query(
				messagesRef,
				where("type", "==", "message"),
				where("erase", "==", false),
				where("uidTo", "==", `${user.id}-messages`),
				where("date", ">=", twentyFourHoursAgo),
				orderBy("date", "desc")
			)

			const unsubscribe = onSnapshot(q, snapshot => {
				const data = snapshot.docs.map(doc => ({
					id: doc.id,
					...doc.data()
				}))

				setAllMessages(data)

				let counter = 0
				data.forEach(message => {
					if (!message.read) {
						counter++
						// playSound();
						setShowAnimation(true)
					}
				})

				if (counter > newMessagesCounter) {
					playSound()
				}

				setNewMessagesCounter(counter)

				if (counter > 0) {
					setbuttonColor("primaryLight")
				}
			})

			return () => {
				unsubscribe()
			}
		} else {
			return
		}
	}, [selectedCustomer, user, messagingSwitch])

	const classes = useStyles()

	return (
		<>
			<Badge
				key={newMessagesCounter}
				color="primary"
				max={99}
				badgeContent={newMessagesCounter}
				className={`${classes.badge} ${
					showAnimation ? classes.shakeAnimation : ""
				} d-flex align-items-center`}
			>
				<OverlayTrigger
					placement="bottom"
					overlay={<Tooltip id="firebase-messaging-tooltip">Messaging</Tooltip>}
				>
					<Button
						color={buttonColor}
						startIcon={<MailOutlinedIcon fontSize="large" className="badge-start-icon" />}
						style={{
							width: "36px",
							height: "36px",
							borderRadius: "25px",
							minWidth: "0"
						}}
						onClick={handleOpenMessaging}
					/>
				</OverlayTrigger>
			</Badge>
			<Modal
				open={open}
				onClose={handleCloseMessaging}
				BackdropComponent={props => <Backdrop {...props} />}
			>
				<div>
					<FirebaseMessagingModalBody allMessages={allMessages} />
				</div>
			</Modal>
		</>
	)
}

export default FirebaseMessaging
