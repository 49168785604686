import * as requestFromServer from "./contactTracingCrud"
import { contactTracingSlice, callTypes } from "./contactTracingSlice"
import { convertCollectionSnapshotToMap } from "../_helpers/ActionHelpers"
import moment from "moment"
import { actionTypes } from "../../../globalUI/snackBar.duck"

const { actions } = contactTracingSlice

// export const fetchUsers = queryParams => dispatch => {
// 	dispatch(actions.startCall({ callType: callTypes.action }))

// 	return requestFromServer
// 		.getUsers(queryParams)
// 		.then(response => {
// 			const users = convertCollectionSnapshotToMap(response)

// 			dispatch(actions.usersFetched(users))
// 		})
// 		.catch(error => {
// 			error.clientMessage = "Can't fetch users"
// 			dispatch({
// 				type: "SNACKBAR_ERROR",
// 				payload: `Error fetching users`
// 			})
// 			dispatch(actions.catchError({ error, callType: callTypes.action }))
// 		})
// }

export const fetchUsersSelectedSite = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.getUsersOnSelectedSite(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}
			// console.log("🚀 ~ fetchUsersSelectedSite ~ response:", response)

			// const people = convertCollectionSnapshotToMap(response[0])

			// const tags = convertCollectionSnapshotToMap(response[1])

			const people = response.people
			const tags = response.nodes

			const peopleOnSelectedSite =
				people &&
				people
					.map(option => {
						if (!option.personalData?.name) return null
						const tag = tags && tags.find(val => val.uuid === option.uuid)
						return {
							gpsData: tag?.gpsData,
							floorPlanId: tag?.floorPlanId,
							siteId: tag?.siteId, // Added the siteId
							...option
						}
					})
					.filter(
						val => queryParams.selectedSite && val?.siteId === queryParams.selectedSite?.id
					) // Filter only people of this selectedSite
					.filter(val => val?.uuid) // filter only ppl with uuid

			dispatch(actions.usersFetched(peopleOnSelectedSite))
		})
		.catch(error => {
			error.clientMessage = "Can't fetch users"
			dispatch({
				type: actionTypes.openSnackBarError,
				payload: {
					message: "Error fetching users"
				}
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const fetchPeopleDataAuditTool = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return Promise.all([
		fetchContactTracing(queryParams, dispatch),
		fetchAuditPersonLocation(queryParams, dispatch)
	]).catch(error => {
		console.log("Error on fetchPeopleDataAuditTool function : ", error)
		error.clientMessage = "Can't fetchPeopleDataAuditTool"
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	})
}

// This function fetches data and populates the audit tool table
const fetchContactTracing = (queryParams, dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return requestFromServer
		.getContactTracing(queryParams)
		.then(response => response.json()) //TODO: tirei o response.json() pois a mockData n é em format json
		.then(result => {
			if (!result) {
				throw Error("Can't make a request for server")
			}

			// console.log("result fetchContactTracing: ", result)

			const data = result.map((val, index) => {
				return {
					id: index,
					date: val[0],
					area: val[2],
					// floorplan: val[2],
					time: val[1]
				}
			})

			dispatch(actions.contactTracingFetched(data))
		})
		.catch(error => {
			console.log("fetch contact tracing data to table.", error)
			error.clientMessage = "Can't fetch contact tracing data to table."
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

// this function fetches the data to populate the audit tool map
const fetchAuditPersonLocation = (queryParams, dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	return requestFromServer
		.getPersonLocationAuditTool(queryParams)
		.then(response => response.json())
		.then(result => {
			if (!result) {
				throw Error("Can't make a request for server")
			}

			const startDate = moment(queryParams?.startDate) // selected day

			// filter result to show only data from the selected day (because backend is sending data from the day before)
			const filteredDataJustSelectedDay = result.filter(val => {
				const objDate = moment(val.date, "ddd, DD MMM YYYY HH:mm:ss ZZ").toDate()
				// Check if the date corresponds to the chosen day
				return moment(objDate).isSame(startDate, "day")
			})

			const data =
				filteredDataJustSelectedDay &&
				filteredDataJustSelectedDay
					.filter(value => value.latitude && value.longitude && value.floorPlanId) // filter only values with lat, long and floorplanId.
					.map((val, index) => {
						if (val) {
							var dateTimeString = val.date

							// Separate the time from the date manually
							var parts = dateTimeString.split(" ")
							// var datePart = parts.slice(1, 4).join(" ") // Extract "12 Sep 2023"
							var timePart = parts[4] // Extract "17:00:04"

							return {
								id: index,
								date: val.date,
								time: timePart,
								floorPlanId: val.floorPlanId,
								latitude: val.latitude,
								longitude: val.longitude,
								uuid: val.uuid
							}
						}
					})

			data && dispatch(actions.personLocationAuditToolFetched(data))
		})
		.catch(error => {
			console.log("fetch person location on audit tool error", error)
			error.clientMessage = "Can't fetch person location on audit tool"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchAllPeopleToTimesheets = queryParams => dispatch => {
	return requestFromServer
		.getAllThePeopleDistribution(queryParams)
		.then(response => response && response.json())
		.then(result => {
			if (!result) {
				throw Error("Can't make a request for server")
			}

			const data = []

			result.forEach((val, i) => {
				if (!val[1] || !val[7] || !val[8]) return

				data.push({
					uuid: val[1]
				})
			})

			return data
		})

		.catch(error => {
			console.log("Fetch all People distribution: ", error)
			error.clientMessage = "Can't fetch all People distribution"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}
